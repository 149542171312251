import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import EditDocumentModal from './EditDocumentModal'; // Ensure this path matches where you save the modal component
import './FileViewer.css';

const FileViewer = () => {
  const [documents, setDocuments] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchDocuments = async () => {
    const db = getFirestore();
    const querySnapshot = await getDocs(collection(db, "files"));
    setDocuments(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  useEffect(() => {
    fetchDocuments();
  }, []); 

  const determineStatus = (doc) => {
    if (!doc.sketchCreated) return "Awaiting Sketch";
    if (doc.sketchCreated && !doc.bomCreated) return "Awaiting BOM";
    if (doc.sketchCreated && doc.bomCreated && !doc.orderChecked) return "Awaiting Order Check";
    if (doc.sketchCreated && doc.bomCreated && doc.orderChecked && !doc.customerEmailed) return "Awaiting Email to Customer";
    if (doc.sketchCreated && doc.bomCreated && doc.orderChecked && doc.customerEmailed && !doc.customerApproved) return "Awaiting Approval"
    if (doc.sketchCreated && doc.bomCreated && doc.orderChecked && doc.customerEmailed && doc.customerApproved && !doc.programComplete) return "Awaiting Program"
    if (doc.sketchCreated && doc.bomCreated && doc.orderChecked && doc.customerEmailed && doc.customerApproved && doc.programComplete) return "Ready for Production"
    return "Complete"; // Assumes all steps are completed if none of the above conditions are met
  };

  const filteredDocuments = documents.filter(doc =>
    doc.customerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    doc.serialNumber.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <h1>Sketches</h1>
      <input
        type="text"
        placeholder="Search documents..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <table className="file-table">
        <thead>
          <tr>
            <th>Serial Number</th>
            <th>Customer Name</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredDocuments.map(doc => (
            <tr key={doc.id} onClick={() => setSelectedDoc(doc)}>
              <td>{doc.serialNumber}</td>
              <td>{doc.customerName}</td>
              <td>{determineStatus(doc)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedDoc && <EditDocumentModal documentData={selectedDoc} onSaveSuccess={fetchDocuments} onClose={() => setSelectedDoc(null)} />}
    </div>
  );
};

export default FileViewer;
