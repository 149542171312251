import React, { useState, useEffect, useRef } from 'react';
import { collection, addDoc, getFirestore, Timestamp, doc, getDoc } from 'firebase/firestore';
import './BookingIn.css';

const StainlessPunch = () => {
  const [barcode, setBarcode] = useState('');
  const firestore = getFirestore();
  
  // Ref for the barcode input
  const barcodeInputRef = useRef(null);

  useEffect(() => {
    // Automatically focus on the barcode input when the page loads
    barcodeInputRef.current.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const serialNumber = barcode.slice(0, 6);
    const quantityNumber = barcode.slice(6, 8);
    const item = barcode.slice(8);

    // Check if the barcode already exists
    const docRef = doc(firestore, 'stainlesspunch', barcode);
    const docSnap = await getDoc(docRef);

    // If the barcode exists, prevent the submission and alert the user
    if (docSnap.exists()) {
      console.log('Duplicate barcode. Upload failed.');
      setBarcode('');
      barcodeInputRef.current.focus();
      return;
    }

    const productData = {
      SerialNumber: serialNumber,
      QuantityNumber: quantityNumber,
      Item: item,
      TimeScannedIn: Timestamp.now(),
    };

    try {
      await addDoc(collection(firestore, 'stainlesspunch'), productData, barcode);
      console.log("Successful upload!");
    } catch (error) {
      console.error("Error adding data to Firestore: ", error);
    }

    // Reset the barcode field and refocus for the next input
    setBarcode('');
    barcodeInputRef.current.focus();
  };

  return (
    <>
      <div className="BookInBody">
        <div className="bookingContainer">
          <h1>Barcode Scanning:</h1>
          <form onSubmit={handleSubmit}>
            <label htmlFor="barcode">Scan Barcode:</label>
            <input 
              type="text" 
              id="barcode" 
              name="barcode" 
              required 
              value={barcode} 
              onChange={e => setBarcode(e.target.value)} 
              ref={barcodeInputRef}
            />
            <button type="submit" style={{ display: 'none' }}>Submit</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default StainlessPunch;