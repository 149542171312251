import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';
import './WarehouseView.css';
import FavouriteButton from './FavouriteButton';

function PartsTable() {
  const [allParts, setAllParts] = useState([]); // Store all parts as an array
  const [searchTerm, setSearchTerm] = useState(''); // Search term state

  useEffect(() => {
    const fetchParts = async () => {
      const q = query(collection(db, "partsPrices"), orderBy("category"));
      const querySnapshot = await getDocs(q);
      const parts = [];
      
      querySnapshot.forEach((doc) => {
        parts.push(doc.data());
      });

      setAllParts(parts);
    };

    fetchParts();
  }, []);

  // Filter parts based on the search term
  const filteredParts = allParts.filter((part) =>
    part.desc.toLowerCase().includes(searchTerm) ||
    part.code.toLowerCase().includes(searchTerm)
  );

  // Group the filtered parts by category
  const partsByCategory = filteredParts.reduce((acc, part) => {
    if (!acc[part.category]) {
      acc[part.category] = [];
    }
    acc[part.category].push(part);
    return acc;
  }, {});

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  return (
    <>
    <FavouriteButton pageUrl={window.location.pathname} />
    <div>
      <div className="search-bar-container">
        <input
          type="text"
          placeholder="Search by description or code..."
          onChange={handleSearchChange}
          className="search-input"
        />
      </div>
      {Object.keys(partsByCategory).map((category) => (
        <div key={category}>
          <h3 style={{ color: '#5c6bc0', marginBottom: '10px', textAlign: 'center' }}>{category}</h3>
          <table className="warehouse-table">
            <thead>
              <tr>
                <th>Code</th>
                <th>Description</th>
                <th>Merchant</th>
                <th>Retail Customer</th>
              </tr>
            </thead>
            <tbody>
              {partsByCategory[category].map((part, index) => (
                <tr key={index}>
                  <td>{part.code}</td>
                  <td>{part.desc}</td>
                  <td>£{part.costexvat}</td>
                  <td>£{part.costincvat}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
    </>
  );
}

export default PartsTable;
