import React, { useState } from 'react';
import FavouriteButton from './FavouriteButton';

function HeatUpCalculator() {
  const [capacity, setCapacity] = useState('');
  const [temperatureRise, setTemperatureRise] = useState('50');
  const [powerInput, setPowerInput] = useState('');
  const [time, setTime] = useState('');

  const calculateTime = () => {
    const energyNeeded = capacity * temperatureRise;
    const energySupplied = 14.3 * powerInput; // Assuming 14.3 is a fixed balancer value
    const timeNeeded = energyNeeded / energySupplied;
    // Convert time to hours and minutes
    const hours = Math.floor(timeNeeded / 60);
    const minutes = Math.round(timeNeeded % 60);
    setTime(`${hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''} and ` : ''}${minutes} minute${minutes !== 1 ? 's' : ''}`);
  };

  return (
    <>
    <FavouriteButton pageUrl={window.location.pathname} />
    <div style={styles.container}>
      <h2 style={styles.header}>Heat Up Time Calculator</h2>
      <div style={styles.inputGroup}>
        <label style={styles.label}>
          Capacity (L):
          <input
            type="number"
            value={capacity}
            onChange={(e) => setCapacity(e.target.value)}
            style={styles.input}
          />
        </label>
      </div>
      <div style={styles.inputGroup}>
        <label style={styles.label}>
          Temperature Rise (°C):
          <input
            type="number"
            value={temperatureRise}
            onChange={(e) => setTemperatureRise(e.target.value)}
            style={styles.input}
          />
        </label>
      </div>
      <div style={styles.inputGroup}>
        <label style={styles.label}>
          kW Input:
          <input
            type="number"
            value={powerInput}
            onChange={(e) => setPowerInput(e.target.value)}
            style={styles.input}
          />
        </label>
      </div>
      <button onClick={calculateTime} style={styles.button}>Calculate Time</button>
      <h3 style={styles.result}>Heat Up Time: {time}</h3>
    </div>
  </>
  );
}

// Add your CSS styles here
const styles = {
    container: {
      margin: '0 auto',
      marginTop: '20px',
      maxWidth: '500px',
      padding: '20px',
      border: '1px solid #ccc',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      backgroundColor: '#f9f9f9',
    },
    header: {
      textAlign: 'center',
      color: '#333',
    },
    inputGroup: {
      marginBottom: '15px',
    },
    label: {
      display: 'block',
      marginBottom: '5px',
      color: '#666',
    },
    input: {
      width: '100%',
      padding: '8px',
      margin: '0 0 10px 0',
      border: '1px solid #ddd',
      borderRadius: '4px',
      boxSizing: 'border-box', // Makes sure the padding doesn't affect the overall width
    },
    button: {
      width: '100%',
      backgroundColor: '#5c6bc0',
      color: 'white',
      padding: '10px',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    result: {
      textAlign: 'center',
      color: '#5c6bc0',
    },
  };
export default HeatUpCalculator;
