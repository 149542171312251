// SketchDashboard.jsx
import React from 'react';
import './Dashboard.css';
import plusIcon from './assets/plus-button.png';
import returnsIcon from './assets/product-return.png';
import { useNavigate } from 'react-router-dom';
import FavouriteButton from './FavouriteButton';

const ReturnsDashboard = () => {
  const navigate = useNavigate();


  const handleReturnEntryClick = () => {
    navigate('/ReturnsEntry');
  };

  const handleReturnsClick = () => {
    navigate('/ReturnsList');
  }

  
  return (
    <>
    <FavouriteButton pageUrl={window.location.pathname} />
    <div className="dashboard-container">
      <div className="dashboard-header">
        <h1>Returns Dashboard</h1>
        {/* You can add a logout or navigation buttons here if needed */}
      </div>
      <div className="widget-container">
        {/* Add widgets and content specific to the Warehouse Dashboard */}
        <div className="widget" onClick={handleReturnEntryClick}>
                <img src={plusIcon} alt="File Upload" />
                <p>New Return</p>
                {/* Additional content for Helpdesk */}
              </div>
        <div className="widget" onClick={handleReturnsClick}>
                <img src={returnsIcon} alt="File Upload" />
                <p>View Returns</p>
                {/* Additional content for Helpdesk */}
              </div>
        {/* Add more widgets as needed */}
      </div>
    </div>
    </>
  );
};

export default ReturnsDashboard;
