import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';
import { getAuth } from 'firebase/auth';

const DeliveryViewer = () => {
  const [deliveries, setDeliveries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterNames, setFilterNames] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);

  const auth = getAuth();

  // Fetch deliveries from Firestore
  useEffect(() => {
    const fetchDeliveries = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'deliveries'));
        const deliveryData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDeliveries(deliveryData);

        // Extract unique names for filtering
        const uniqueNames = [...new Set(deliveryData.map((delivery) => delivery.userName))];
        setFilterNames(uniqueNames);
      } catch (error) {
        console.error('Error fetching deliveries: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDeliveries();
  }, []);

  // Handle checkbox changes to filter by selected names
  const handleCheckboxChange = (name) => {
    if (selectedNames.includes(name)) {
      setSelectedNames(selectedNames.filter((n) => n !== name));
    } else {
      setSelectedNames([...selectedNames, name]);
    }
  };

  // Filter deliveries based on selected names
  const filteredDeliveries = selectedNames.length
    ? deliveries.filter((delivery) => selectedNames.includes(delivery.userName))
    : deliveries;

  return (
    <div>
      <h1>View Deliveries</h1>
      {loading ? (
        <p>Loading deliveries...</p>
      ) : (
        <div>
          <div style={{ marginBottom: '20px' }}>
            <h3>Filter by User Name:</h3>
            {filterNames.map((name) => (
              <label key={name} style={{ marginRight: '10px' }}>
                <input
                  type="checkbox"
                  checked={selectedNames.includes(name)}
                  onChange={() => handleCheckboxChange(name)}
                />
                {name}
              </label>
            ))}
          </div>

          {filteredDeliveries.length === 0 ? (
            <p>No deliveries found.</p>
          ) : (
            filteredDeliveries.map((delivery) => (
              <div
                key={delivery.id}
                style={{
                  border: '1px solid #ddd',
                  padding: '15px',
                  marginBottom: '15px',
                  borderRadius: '5px',
                }}
              >
                <h3>Barcode: {delivery.barcode}</h3>
                <p>Signer's Name: {delivery.signerName || 'N/A'}</p>
                {delivery.image && (
                  <div>
                    <a href={delivery.image} target="_blank" rel="noopener noreferrer">
                      <img
                        src={delivery.image}
                        alt="Uploaded delivery"
                        style={{
                          maxWidth: '150px',
                          height: 'auto',
                          marginBottom: '10px',
                          cursor: 'pointer',
                          borderRadius: '5px',
                          border: '1px solid #ddd',
                        }}
                      />
                    </a>
                  </div>
                )}
                {delivery.signature && (
                  <div>
                    <h4>Signature:</h4>
                    <img
                      src={delivery.signature}
                      alt="Signature"
                      style={{
                        maxWidth: '150px',
                        height: 'auto',
                        marginBottom: '10px',
                        cursor: 'pointer',
                        borderRadius: '5px',
                        border: '1px solid #ddd',
                      }}
                    />
                  </div>
                )}
                {delivery.location && (
                  <p>
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${delivery.location.latitude},${delivery.location.longitude}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Location
                    </a>
                  </p>
                )}
                {delivery.timestamp && (
                  <p>Delivered {delivery.timestamp.toDate().toLocaleString()}</p>
                )}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default DeliveryViewer;
