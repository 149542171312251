import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TechnicalCalculator.css';
import FavouriteButton from './FavouriteButton';

const TechnicalCalculator = () => {
  const navigate = useNavigate();

  const handleHeatLossClick = () => {
    navigate('/HeatLossCalculator');
  };

  const handleHeatUpClick = () => {
    navigate('/HeatUpCalculator');
  };

  const handleTimeReqClick = () => {
    navigate('/TimeRequiredCalculator');
  };

  const handleCylSizeClick = () => {
    navigate('/CylinderSizingCalculator');
  };

  const handleExpSizeClick = () => {
    navigate('/ExpansionVesselCalculator');
  };

  return (
    <>
    <FavouriteButton pageUrl={window.location.pathname} />
    <div>
    <h1>Calculators</h1>
    <div className="list-container">
      <ul>
        <li className="list" onClick={handleHeatLossClick}>
          <p>Heat Loss Calculator</p>
        </li>
        <li className="list" onClick={handleHeatUpClick}>
          <p>Heat Up kW Required Calculator</p>
        </li>
        <li className="list" onClick={handleTimeReqClick}>
          <p>Heat Up Time Required Calculator</p>
        </li>
        <li className="list" onClick={handleCylSizeClick}>
          <p>Cylinder Sizing</p>
        </li>
        <li className="list" onClick={handleExpSizeClick}>
          <p>Expansion Vessel Sizing</p>
        </li>
        {/* Add more list items for other calculators here */}
      </ul>
    </div>
    </div>
  </>
  );
};

export default TechnicalCalculator;
