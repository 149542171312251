import React, { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import StarIcon from './assets/star-icon.png'; // Adjust paths as needed
import StarIconActive from './assets/star-icon-active.png'; // Adjust paths as needed

const FavouriteButton = ({ pageUrl }) => {
  const [isFavourited, setIsFavourited] = useState(false);
  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const checkFavouriteStatus = async () => {
      if (user) {
        console.log("user logged in")
        const userEmail = user.email;
        const userRef = doc(db, 'Users', userEmail);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          const favourites = docSnap.data().favourites || [];
          setIsFavourited(favourites.includes(pageUrl));
        }
      }
    };

    checkFavouriteStatus();
  }, [db, user, pageUrl]);

  const handleFavouriteClick = async () => {
    if (user) {
      
      console.log("Attempting to update favourites...");
      const userEmail = user.email;
      const userRef = doc(db, 'Users', userEmail);
      try {
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          let favourites = docSnap.data().favourites || [];
          if (favourites.includes(pageUrl)) {
            favourites = favourites.filter(fav => fav !== pageUrl);
            setIsFavourited(false);
          } else {
            favourites.push(pageUrl);
            setIsFavourited(true);
          }
          
          await updateDoc(userRef, { favourites });
          console.log("Favourites updated:", favourites);
        } else {
          console.log("Document does not exist");
        }
      } catch (error) {
        console.error("Error updating document:", error);
      }
    }
  };
  

  return (
    <button onClick={handleFavouriteClick} style={{ background: 'none', border: 'none', display: 'flex', alignItems: 'center', color: 'black' }}>
  <img src={isFavourited ? StarIconActive : StarIcon} alt="Favourite" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
  <span style={{ fontSize: '14px' }}>Add to favourites</span>
</button>


  );
};

export default FavouriteButton;