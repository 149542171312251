import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';
import './CopperQuote.css';
import FavouriteButton from './FavouriteButton';

const CopperQuote = () => {
  const [options, setOptions] = useState({});
  const [totalCost, setTotalCost] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, 'quotePrices'), where("type", "==", "copper"));
      const querySnapshot = await getDocs(q);
      const docs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Group documents by category
      const grouped = docs.reduce((acc, doc) => {
        (acc[doc.category] = acc[doc.category] || []).push(doc);
        return acc;
      }, {});

      setOptions(grouped);
    };

    fetchData();
  }, []);

  const handleOptionChange = (option, value) => {
    const { cost, id } = option;
    const numericCost = parseFloat(cost);
    
    // Finding the option in the selectedOptions to update quantity or remove it
    const existingOptionIndex = selectedOptions.findIndex(o => o.id === id);
    
    if (option.qtyType === "CheckBox") {
      if (value) { // If checkbox is checked
        setSelectedOptions([...selectedOptions, { ...option, quantity: 1 }]);
        setTotalCost(totalCost + numericCost);
      } else if (existingOptionIndex !== -1) { // If checkbox is unchecked
        setSelectedOptions(selectedOptions.filter(o => o.id !== id));
        setTotalCost(totalCost - numericCost);
      }
    } else if (option.qtyType === "Multiple") {
      const quantity = parseInt(value, 10) || 0;
      const previousOption = selectedOptions[existingOptionIndex];
      const previousQuantity = previousOption ? previousOption.quantity : 0;
      
      if (existingOptionIndex !== -1) {
        const newSelectedOptions = [...selectedOptions];
        if (quantity > 0) {
          newSelectedOptions[existingOptionIndex] = { ...option, quantity };
        } else {
          newSelectedOptions.splice(existingOptionIndex, 1);
        }
        setSelectedOptions(newSelectedOptions);
      } else if (quantity > 0) {
        setSelectedOptions([...selectedOptions, { ...option, quantity }]);
      }
      
      // Adjusting the total cost based on the new quantity
      setTotalCost(totalCost + (quantity - previousQuantity) * numericCost);
    }
  };

  const sendEmail = () => {
    // Assuming you'll fill these in as needed
  
    const selectedItemsString = selectedOptions.map(o => `- ${o.desc}`).join('\n'); // Each item on a new line
  
    const mailtoLink = `mailto:?subject=Your Custom Quote&body=${encodeURIComponent(
      `Thank you for your enquiry,\n\n` +
      `Your price below:\n\n` +
      `Product Code:\n` +
      `Lead Time: 5-7 Working Days` +
      `Price: £${totalCost.toFixed(2)}\n` +
      `Carriage: £25.00 + VAT` +
      `Specification:\n${selectedItemsString}`
    )}`;
  
    window.location.href = mailtoLink;
  };

  return (
    <>
      <FavouriteButton pageUrl={window.location.pathname} />
      <div>
        <div>
          <h1>Copper Quoting Matrix</h1>
          <label>Please enter base price: </label>
          <input
            type="text"
            onChange={e => setTotalCost(parseFloat(e.target.value))}
            placeholder="£0.00"
          />
        </div>
        {Object.entries(options).map(([category, items]) => (
          <div key={category} className="category-container">
            <h3>{category}</h3>
            {items.map((option) => (
              <div key={option.id} className="option-container">
                <label htmlFor={option.id}>{option.desc} (£{option.cost})</label>
                {option.qtyType === "CheckBox" ? (
                  <input
                    type="checkbox"
                    id={option.id}
                    onChange={e => handleOptionChange(option, e.target.checked)}
                  />
                ) : (
                  <input
                    type="number"
                    id={`qty-${option.id}`}
                    min="0"
                    onChange={e => handleOptionChange(option, e.target.value)}
                    placeholder="Qty"
                  />
                )}
              </div>
            ))}
          </div>
        ))}
        <div>Total Cost: £{totalCost.toFixed(2)}</div>
        <button onClick={sendEmail}>Send Quote</button>
      </div>
    </>
  );
};

export default CopperQuote;
