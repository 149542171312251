// src/components/NoticeBoard.js

import React, { useState, useEffect } from 'react';
import { uploadFileToNotice, listFilesInNotice, deleteFileFromNotice } from '../firebase/firebaseService';
import './NoticeUpload.css';
import FavouriteButton from './FavouriteButton';

const NoticeUpload = () => {
  const [files, setFiles] = useState([]);
  const [fileToUpload, setFileToUpload] = useState(null);

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    const files = await listFilesInNotice();
    setFiles(files);
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files).filter(file => file.type === "application/pdf");
    if (selectedFiles.length !== event.target.files.length) {
      alert("Only PDF files are allowed.");
    } else {
      setFileToUpload(selectedFiles);
      event.target.value = ''; // Reset the file input
    }
  };

  const handleUpload = async () => {
    if (fileToUpload && fileToUpload.length > 0) {
      for (const file of fileToUpload) {
        await uploadFileToNotice(file);
      }
      fetchFiles(); // Refresh the list after uploading
    }
  };

  const handleDelete = async (fileName) => {
    await deleteFileFromNotice(fileName);
    fetchFiles(); // Refresh the list after deleting
  };

  return (
    <>
    <FavouriteButton pageUrl={window.location.pathname} />
    <div className="notice-upload">
      <div className="upload-container">
        <input type="file" onChange={handleFileChange} accept=".pdf" multiple />
        <button onClick={handleUpload}>Upload</button>
      </div>
      <div className="files-container">
        <ul>
          {files.map((file) => (
            <li key={file.name}>
              {file.name} - <a className="button" href={file.url} target="_blank" rel="noopener noreferrer">View</a> | <button onClick={() => handleDelete(file.name)}>Delete</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
    </>
  );
};


export default NoticeUpload;
