import React, { useState, useEffect, useMemo } from 'react';
import { getFirestore, collection, doc, getDoc, getDocs } from 'firebase/firestore';
import * as XLSX from 'xlsx';
import './WarehouseView.css';
import FavouriteButton from './FavouriteButton';

const SalesWarehouse = () => {
  const [cylinders, setCylinders] = useState([]);
  const [viewMode, setViewMode] = useState('table');
  const [selectedProductCode, setSelectedProductCode] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  useEffect(() => {
    const firestore = getFirestore();

    const fetchCode = async (productId) => {
      if (!productId || typeof productId !== 'string') {
        return 'N/A';
      }
      const docRef = doc(firestore, 'cyl_info', productId);
      const docSnap = await getDoc(docRef);
      return docSnap.exists() ? docSnap.data().productCode : 'N/A';
    };

    const fetchLocation = async (LocationId) => {
      if (LocationId.length < 5) return LocationId;
      const locationsRef = collection(firestore, 'warehouse_locations');
      const querySnapshot = await getDocs(locationsRef);
      const uniqueId = LocationId.slice(-5);
      const matchingDoc = querySnapshot.docs.find((doc) => doc.data().barcode.endsWith(uniqueId));
      return matchingDoc ? matchingDoc.id : LocationId;
    };

    const fetchData = async () => {
      const cylindersCollection = collection(firestore, 'cylinders_warehouse');
      const querySnapshot = await getDocs(cylindersCollection);

      const cylindersData = await Promise.all(
        querySnapshot.docs.map(async (docSnapshot) => {
          const cylinderData = docSnapshot.data();
          return {
            id: docSnapshot.id,
            ProductCode: await fetchCode(cylinderData.ProductId),
            SerialNumber: cylinderData.SerialNumber,
            Special: cylinderData.Special,
            BookedInTime: cylinderData.BookedInTime,
            LocationId: await fetchLocation(cylinderData.LocationId),
            ProductId: cylinderData.ProductId,
            Stock: cylinderData.Stock ? 'STOCK' : 'ORDER',
            Allocated: cylinderData.Allocated || false,
          };
        })
      );
      setCylinders(cylindersData);
    };

    fetchData();
  }, []);

  const sortData = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    setCylinders((currentCylinders) => {
      return [...currentCylinders].sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    });
  };

  const formatDate = (timestamp) => {
    return timestamp ? new Date(timestamp.seconds * 1000).toLocaleString() : 'N/A';
  };

  const getSpecialValueLabel = (specialValue) => {
    const specialValueMap = {
      '01': 'G1 - Male',
      '02': 'G1 - Female',
      '03': 'G4 - Male',
      '04': 'G4 - Female',
      '05': 'IDF2 - Male',
      '06': 'IDF2 - Female',
      '07': 'IDH1 - Male',
      '08': 'IDH1 - Female',
      '09': 'IDH2 - Male',
      '10': 'IDH2 - Female',
      '49': 'Standard',
      '56': 'None Standard',
      // Add more mappings here as needed
    };

    return specialValueMap[specialValue] || specialValue; // Return the original code if no mapping found
  };

  const filteredCylinders = useMemo(
    () =>
      cylinders.filter(
        (cylinder) =>
          cylinder.ProductCode.toLowerCase().includes(searchQuery.toLowerCase()) ||
          cylinder.SerialNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (cylinder.LocationId && cylinder.LocationId.toLowerCase().includes(searchQuery.toLowerCase()))
      ),
    [searchQuery, cylinders]
  );

  const aggregatedProducts = useMemo(
    () =>
      filteredCylinders.reduce((acc, cylinder) => {
        const key = cylinder.ProductCode;
        if (!acc[key]) {
          acc[key] = { count: 0, serialNumbers: [] };
        }
        acc[key].count += 1;
        acc[key].serialNumbers.push(cylinder.SerialNumber);
        return acc;
      }, {}),
    [filteredCylinders]
  );

  const exportToExcel = () => {
    const dataToExport = filteredCylinders.map(cylinder => ({
      ProductCode: cylinder.ProductCode,
      SerialNumber: cylinder.SerialNumber,
      Special: getSpecialValueLabel(cylinder.Special),
      BookedInTime: formatDate(cylinder.BookedInTime),
      LocationId: cylinder.LocationId,
      Barcode: `${cylinder.SerialNumber}${cylinder.Special}${cylinder.ProductId}`,
      Stock: cylinder.Stock,
      Allocated: cylinder.Allocated
    }));
    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Cylinders');
    XLSX.writeFile(wb, 'cylinders.xlsx');
  };

  return (
    <>
      <FavouriteButton pageUrl={window.location.pathname} />
      <div>
        <h1>Warehouse Stock</h1>
        <div className='controls-container'>
          <div className='search-container'>
            <input
              type='text'
              placeholder='Search by Product Code, Serial Number or Location...'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className='search-input'
            />
          </div>
          <div className='view-selector'>
            <label htmlFor='viewMode'>View:</label>
            <select id='viewMode' value={viewMode} onChange={(e) => setViewMode(e.target.value)}>
              <option value='table'>Table</option>
              <option value='list'>List</option>
            </select>
          </div>
          <button onClick={exportToExcel} className="export-button">Export to Excel</button>
        </div>
        {viewMode === 'table' ? (
          <table className='warehouse-table'>
            <thead>
              <tr>
                <th className='clickable' onClick={() => sortData('ProductCode')}>
                  Product Code
                </th>
                <th className='clickable' onClick={() => sortData('SerialNumber')}>
                  Serial Number
                </th>
                <th>Type</th>
                <th className='clickable' onClick={() => sortData('BookedInTime')}>
                  Booked In
                </th>
                <th className='clickable' onClick={() => sortData('LocationId')}>
                  Location
                </th>
                <th>Barcode</th>
                <th className='clickable' onClick={() => sortData('Stock')}>
                  Stock Status
                </th>
                <th className='clickable' onClick={() => sortData('Allocated')}>
                  Allocated
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredCylinders.map((cylinder) => (
                <tr key={cylinder.id}>
                  <td>{cylinder.ProductCode}</td>
                  <td>{cylinder.SerialNumber || 'N/A'}</td>
                  <td>{getSpecialValueLabel(cylinder.Special)}</td>
                  <td>{formatDate(cylinder.BookedInTime)}</td>
                  <td>{cylinder.LocationId}</td>
                  <td>{`${cylinder.SerialNumber}${cylinder.Special}${cylinder.ProductId}`}</td>
                  <td>{cylinder.Stock}</td>
                  <td>{cylinder.Allocated ? 'Yes' : 'No'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className='list-container'>
            <ul>
              {Object.entries(aggregatedProducts).map(([code, { count, serialNumbers }]) => (
                <li
                  key={code}
                  onClick={() => setSelectedProductCode(code === selectedProductCode ? null : code)}
                >
                  {code} - {count} QTY
                  {selectedProductCode === code && (
                    <ul>
                      {serialNumbers.map((sn, index) => (
                        <li key={index}>{sn}</li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default SalesWarehouse;
