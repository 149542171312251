import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TechnicalCalculator.css';
import FavouriteButton from './FavouriteButton';

const TechnicalCalculator = () => {
  const navigate = useNavigate();

  const handleStainVentedClick = () => {
    navigate('/StainlessVentedQuote');
  };

  const handleStainUnventedClick = () => {
    navigate('/StainlessUnventedQuote');
  };

  const handleCopperVentedClick = () => {
    navigate('/CopperQuote');
  };

  const handleCopperThermalClick = () => {
    navigate('/CopperThermalQuote');
  };
  
  const handleStainThermalClick = () => {
    navigate('/StainlessThermalQuote');
  };

  return (
    <>
    <FavouriteButton pageUrl={window.location.pathname} />
    <div>
    <h1>Quote Tools</h1>
    <div className="list-container">
      <ul>
        <li className="list" onClick={handleStainVentedClick}>
          <p>Stainless Vented Quote</p>
        </li>
        <li className="list" onClick={handleStainUnventedClick}>
          <p>Stainless Unvented Quote</p>
        </li>
        <li className="list" onClick={handleCopperVentedClick}>
          <p>Copper Vented Quote</p>
        </li>
        <li className="list">
          <p>Copper Thermal Store Quote</p>
        </li>
        <li className="list">
          <p>Stainless Thermal Store Quote</p>
        </li>
        {/* Add more list items for other calculators here */}
      </ul>
    </div>
    </div>
  </>
  );
};

export default TechnicalCalculator;
