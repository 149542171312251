// SketchDashboard.jsx
import React from 'react';
import './Dashboard.css';
import bookinIcon from './assets/signup-icon.png';
import sketchIcon from './assets/sketch-icon.svg';
import { useNavigate } from 'react-router-dom';

const SketchDashboard = () => {
  const navigate = useNavigate();


  const handleFileUploadClick = () => {
    navigate('/FileUpload');
  };

  const handleFileViewerClick = () => {
    navigate('/FileViewer');
  };
  
  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <h1>Sketch Dashboard</h1>
        {/* You can add a logout or navigation buttons here if needed */}
      </div>
      <div className="widget-container">
        {/* Add widgets and content specific to the Warehouse Dashboard */}
        <div className="widget" onClick={handleFileUploadClick}>
                <img src={bookinIcon} alt="File Upload" />
                <p>New Sketch Order</p>
                {/* Additional content for Helpdesk */}
              </div>
        <div className="widget" onClick={handleFileViewerClick}>
                <img src={sketchIcon} alt="Book In" />
                <p>View Current Sketches</p>
                {/* Additional content for Helpdesk */}
              </div>
        {/* Add more widgets as needed */}
      </div>
    </div>
  );
};

export default SketchDashboard;
