// SketchDashboard.jsx
import React from 'react';
import './Dashboard.css';
import unventedIcon from './assets/unvented.png';
import techIcon from './assets/technical-support.png';
import partsIcon from './assets/parts-icon.png';
import searchIcon from './assets/search-engine (1).png';
import ventedIcon from './assets/vented-cylinder.png';
import { useNavigate } from 'react-router-dom';
import FavouriteButton from './FavouriteButton';

const HelpDeskDashboard = () => {
  const navigate = useNavigate();


  const handleUnventedClick = () => {
    navigate('/ProductPage');
  };

  const handleSearchClick = () => {
    navigate('/SearchPage');
  };

  const handleTechnicalClick = () => {
    navigate('/TechnicalDashboard');
  };

  const handlePartsClick = () => {
    navigate('/PartsList');
  };
  
  return (
    <>
    <FavouriteButton pageUrl={window.location.pathname} />
    <div className="dashboard-container">
      <div className="dashboard-header">
        <h1>Help Desk Dashboard</h1>
        {/* You can add a logout or navigation buttons here if needed */}
      </div>

      <div className="widget-container">
        {/* Add widgets and content specific to the Warehouse Dashboard */}
        <div className="widget" onClick={handleSearchClick}>
                <img src={searchIcon} alt="File Upload" />
                <p>Search</p>
                {/* Additional content for Helpdesk */}
              </div>
        <div className="widget">
                <img src={ventedIcon} alt="File Upload" />
                <p>Vented</p>
                {/* Additional content for Helpdesk */}
              </div>
        <div className="widget" onClick={handleUnventedClick}>
                <img src={unventedIcon} alt="Book In" />
                <p>Unvented</p>
                {/* Additional content for Helpdesk */}
              </div>
        <div className="widget" onClick={handleTechnicalClick}>
                <img src={techIcon} alt="Book In" />
                <p>Technical Information</p>
                {/* Additional content for Helpdesk */}
              </div>
        <div className="widget" onClick={handlePartsClick}>
                <img src={partsIcon} alt="Book In" />
                <p>Parts</p>
                {/* Additional content for Helpdesk */}
              </div>
        {/* Add more widgets as needed */}
      </div>
    </div>
  </>
  );
};

export default HelpDeskDashboard;
