import React, { useState } from 'react';
import FavouriteButton from './FavouriteButton';
 
const ExpansionVesselCalculator = () => {
  const [C, setC] = useState(0);
  const [Pi, setPi] = useState('4.5');
  const [Pf, setPf] = useState('7');
  const [V, setV] = useState(0);
 
  const calculateVesselSize = () => {
    const e = 0.02244; // Expansion Co-efficient
    const V = (e * C) / (1 - (Pi / Pf));
    setV(V);
  };
 
  return (
    <>
    <FavouriteButton pageUrl={window.location.pathname} />
    <div style={styles.container}>
      <h2 style={styles.header}>Expansion Vessel Size Calculator</h2>
      <div style={styles.inputGroup}>
        <label style={styles.label}>
        Total Water Capacity (C in Litres):
        <input
          type="number"
          value={C}
          onChange={(e) => setC(parseFloat(e.target.value))}
        />
      </label>
      </div>
      <div style={styles.inputGroup}>
        <label style={styles.label}>
        Initial Charge Pressure (Pi in Bar):
        <input
          type="number"
          value={Pi}
          onChange={(e) => setPi(parseFloat(e.target.value))}
        />
      </label>
      </div>
      <div style={styles.inputGroup}>
        <label style={styles.label}>
        Maximum Operating Pressure (Pf in Bar):
        <input
          type="number"
          value={Pf}
          onChange={(e) => setPf(parseFloat(e.target.value))}
        />
      </label>
      </div>
      <button onClick={calculateVesselSize} style={styles.button}>Calculate</button>
      <div> <br />
        Expansion Vessel Size: {V.toFixed(2)} Litres
      </div>
    </div>

    <div style={styles.container}>
      <h2 style={styles.header}>Expansion Vessels</h2>
      <ul>
      <li>
        12L Expansion Vessel
      </li>
      <li>
        18L Expansion Vessel
      </li>
      <li>
        24L Expansion Vessel
      </li>
      <li>
        35L Expansion Vessel
      </li>
      <li>
        50L Expansion Vessel
      </li>
        </ul>
      </div>
    </>
  );
};

const styles = {
    container: {
      margin: '0 auto',
      marginTop: '20px',
      maxWidth: '500px',
      padding: '20px',
      border: '1px solid #ccc',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      backgroundColor: '#f9f9f9',
    },
    header: {
      textAlign: 'center',
      color: '#333',
    },
    inputGroup: {
      marginBottom: '15px',
    },
    label: {
      display: 'block',
      marginBottom: '5px',
      color: '#666',
    },
    input: {
      width: '100%',
      padding: '8px',
      margin: '0 0 10px 0',
      border: '1px solid #ddd',
      borderRadius: '4px',
      boxSizing: 'border-box', // Makes sure the padding doesn't affect the overall width
    },
    button: {
      width: '100%',
      backgroundColor: '#5c6bc0',
      color: 'white',
      padding: '10px',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    result: {
      textAlign: 'center',
      color: '#5c6bc0',
    },
  };
 
export default ExpansionVesselCalculator;