// SketchDashboard.jsx
import React from 'react';
import './Dashboard.css';
import bookinIcon from './assets/signup-icon.png';
import noticeIcon from './assets/notice-board.png';
import machinesIcon from './assets/machinery.png';
import { useNavigate } from 'react-router-dom';
import FavouriteButton from './FavouriteButton'; // Adjust the path as necessary


const NoticeDashboard = () => {
  const navigate = useNavigate();


  const handleNoticeViewClick = () => {
    navigate('/NoticeBoard');
  };

  const handleMachineStatusClick = () => {
    navigate('/Machines');
  };

  const handleNoticeUploadClick = () => {
    navigate('/NoticeUpload');
  };

  
  
  return (
<>
    <FavouriteButton pageUrl={window.location.pathname} />

    <div className="dashboard-container">
      <div className="dashboard-header">
        <h1>Notice Dashboard</h1>
        {/* You can add a logout or navigation buttons here if needed */}
      </div>
      <div className="widget-container">
        {/* Add widgets and content specific to the Warehouse Dashboard */}
        <div className="widget" onClick={handleNoticeUploadClick}>
                <img src={bookinIcon} alt="File Upload" />
                <p>Upload Files to Notice Board</p>
                {/* Additional content for Helpdesk */}
              </div>
        <div className="widget" onClick={handleMachineStatusClick}>
                <img src={machinesIcon} alt="File Upload" />
                <p>Machine Status</p>
                {/* Additional content for Helpdesk */}
              </div>
        <div className="widget" onClick={handleNoticeViewClick}>
                <img src={noticeIcon} alt="Book In" />
                <p>View Notice Board</p>
                {/* Additional content for Helpdesk */}
              </div>
        {/* Add more widgets as needed */}
      </div>
    </div>
  </>
  );
};

export default NoticeDashboard;
