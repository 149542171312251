import React, { useState, useEffect, useRef } from 'react';
import { collection, addDoc, getFirestore, Timestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './BookingIn.css';
import FavouriteButton from './FavouriteButton';

const WarehouseBookingInForm = () => {
  const [barcode, setBarcode] = useState('');
  const [locationBarcode, setLocationBarcode] = useState('');
  const firestore = getFirestore();
  const navigate = useNavigate(); // Add navigation hook

  // Refs for the input fields
  const barcodeInputRef = useRef(null);
  const locationInputRef = useRef(null);

  useEffect(() => {
    // Automatically focus on the barcode input when the page loads
    barcodeInputRef.current.focus();
  }, []);

  const handleBarcodeKeyDown = (event) => {
    if (event.key === 'Enter' && barcode) {
      event.preventDefault(); // Prevent form submission
      if (barcode === '1234567891234') {
        navigate('/WarehouseBookingInForm'); // Redirect to WarehouseBookingInForm
      } else if (barcode === '1234567891235') {
        navigate('/WarehouseBookingStock'); // Redirect to WarehouseBookingStock
      } else {
        locationInputRef.current.focus(); // Move focus to the locationBarcode input
      }
    }
  };

  const handleLocationBarcodeKeyDown = (event) => {
    if (event.key === 'Enter' && locationBarcode) {
      event.preventDefault(); // Prevent default to manually handle the submission logic
      handleSubmit(event); // Call the form submission
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const serialNumber = barcode.slice(0, 6);
    const special = barcode.slice(6, 8);
    const productId = barcode.slice(8);
    const locationId = locationBarcode;

    const productData = {
      ProductId: productId,
      Special: special,
      SerialNumber: serialNumber,
      LocationId: locationId,
      Allocated: false,
      Stock: false,
      Dispatch: false,
      currentlyEditingBy: null,
      BookedInTime: Timestamp.now(),
    };

    try {
      await addDoc(collection(firestore, 'cylinders_warehouse'), productData);
      console.log("Data added to Firestore!");
    } catch (error) {
      console.error("Error adding data to Firestore: ", error);
    }

    // Reset fields and focus back on the barcode input after submission
    setBarcode('');
    setLocationBarcode('');
    barcodeInputRef.current.focus();
  };

  return (
    <>
      <FavouriteButton pageUrl={window.location.pathname} />
      <div className="BookInbody">
        <div className="bookincontainer">
          <h1>(NONE STOCK)</h1> <h1>Booking in:</h1>
          <form onSubmit={handleSubmit}>
            <label htmlFor="barcode">Scan or Enter Barcode:</label>
            <input 
              type="text" 
              id="barcode" 
              name="barcode" 
              required 
              value={barcode} 
              onChange={e => setBarcode(e.target.value)} 
              onKeyDown={handleBarcodeKeyDown}
              ref={barcodeInputRef}
            />

            <label htmlFor="locationBarcode">Scan or Enter Location Barcode:</label>
            <input 
              type="text" 
              id="locationBarcode" 
              name="locationBarcode" 
              required 
              value={locationBarcode} 
              onChange={e => setLocationBarcode(e.target.value)} 
              onKeyDown={handleLocationBarcodeKeyDown}
              ref={locationInputRef} 
            />
            <button type="submit" style={{ display: 'none' }}>Submit</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default WarehouseBookingInForm;
