import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, addDoc, getFirestore, serverTimestamp } from 'firebase/firestore';
import './Dashboard.css'; // Ensure CSS is linked correctly

const SignInOutSystem = () => {
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(false); // State to handle loading status
  const firestore = getFirestore();

  useEffect(() => {
    setLoading(true); // Set loading to true while fetching
    const fetchDepartments = async () => {
      const snapshot = await getDocs(collection(firestore, 'departments'));
      const deptData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setDepartments(deptData);
      setLoading(false); // Set loading to false after fetching
    };

    fetchDepartments();
  }, []);

  useEffect(() => {
    const fetchEmployees = async () => {
      if (selectedDepartment) {
        setLoading(true);
        const employeesRef = collection(firestore, `departments/${selectedDepartment.id}/employees`);
        const snapshot = await getDocs(employeesRef);
        const employeesData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setEmployees(employeesData);
        setLoading(false);
      } else {
        setEmployees([]); // Clear employees when no department is selected
      }
    };

    fetchEmployees();
  }, [selectedDepartment, firestore]);

  const selectDepartment = (dept) => {
    setSelectedDepartment(dept);
  };

  const handleEmployeeClick = async (employee) => {
    setLoading(true);  // Start loading
    const newStatus = !employee.status; // Toggle the current status
    const now = new Date(); // Get the current time for the timestamp
    const employeeRef = doc(firestore, `departments/${selectedDepartment.id}/employees/${employee.id}`);

    try {
        // Update the employee's status
        await updateDoc(employeeRef, {
          status: newStatus
        });

        // Log the activity
        const activityRef = collection(employeeRef, 'activity');
        await addDoc(activityRef, {
          timestamp: serverTimestamp(),
          action: newStatus ? 'signed_in' : 'signed_out'
        });

        // Show notification
        setNotification(`User ${newStatus ? 'checked in' : 'checked out'} at ${now.toLocaleTimeString()}`);

        // Hide notification and reset after 3 seconds
        setTimeout(() => {
            setNotification(null);
            setSelectedDepartment(null); // Move back to department selection
        }, 1250);

    } catch (error) {
        console.error("Failed to update or log activity:", error);
        setNotification(`Failed to ${newStatus ? 'check in' : 'check out'}. Please try again.`);
        setTimeout(() => {
            setNotification(null);
        }, 1250);
    } finally {
        setLoading(false);  // End loading
    }
  };

  return (
    <div className="dashboard-container">
      {loading && <p>Loading...</p>}
      {notification && <div className="notification">{notification}</div>}
      {!selectedDepartment && !notification && (
        <div className="widget-container">
          {departments.map(dept => (
            <div key={dept.id} className="widget" onClick={() => selectDepartment(dept)}>
              <h2>{dept.name}</h2>
              <p>{dept.description}</p>
            </div>
          ))}
        </div>
      )}
      {selectedDepartment && !notification && (
        <>
          <button onClick={() => setSelectedDepartment(null)}>Back to Departments</button> <br /> <br />
          <div className="widget-container">
            {employees.map(emp => (
              <div key={emp.id} className="widget" onClick={() => handleEmployeeClick(emp)}
                   style={{ backgroundColor: emp.status ? 'green' : 'red' }}>
                <h3>{emp.name}</h3>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default SignInOutSystem;
