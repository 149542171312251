// FavouritesModal.jsx
import React from 'react';
import './FavouritesModal.css';

const FavouritesModal = ({ isOpen, onClose, favourites }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>Close</button>
        <h2>Favourites</h2>
        {favourites.length > 0 ? (
          <ul>
            {favourites.map((favPath, index) => (
              <li key={index}>
                <a href={favPath} rel="noopener noreferrer">
                  {favPath}
                </a>
              </li>
            ))}
          </ul>
        ) : (
          <p>You have no favourites</p>
        )}
      </div>
    </div>
  );
};

export default FavouritesModal;
