import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import mapIcon from './assets/bigmap-icon.png';
import partsIcon from './assets/parts-icon.png';
import poundIcon from './assets/pound.png';
import waresaleIcon from './assets/warehousesales.png';
import productIcon from './assets/product-return.png';
import allocateIcon from './assets/allocate.png';  // Add the icon for the internal widget
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import FavouriteButton from './FavouriteButton';

const SalesDashboard = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const userEmail = auth.currentUser?.email;
      if (userEmail) {
        const db = getFirestore();
        const userDocRef = doc(db, "Users", userEmail);
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserData(userData); // This now includes the role
        } else {
          console.log("No user data found!");
        }
      }
    };

    fetchData();
  }, [auth]);

  const handleMerchantMapClick = () => {
    navigate('/MerchantMap');
  };

  const handleQuoteClick = () => {
    navigate('/ComingSoon');
  };

  const handlePartsClick = () => {
    navigate('/PartsPrices');
  };

  const handleWarehouseSalesClick = () => {
    navigate('/SalesWarehouse');
  };

  const handleAllocationClick = () => {
    navigate('/StockAllocation');
  };

  const handleWareArch = () => {
    navigate('/WarehouseArchive');
  };


  const handleReorderClick = () => {
    navigate('/ReorderTable');
  };

  return (
    <>
      <FavouriteButton pageUrl={window.location.pathname} />
      <div className="dashboard-container">
        <div className="dashboard-header">
          <h1>Sales Dashboard</h1>
        </div>
        <div className="widget-container">
          <div className="widget" onClick={handlePartsClick}>
            <img src={partsIcon} alt="Parts Prices" />
            <p>Parts Prices</p>
          </div>
          <div className="widget" onClick={handleQuoteClick}>
            <img src={poundIcon} alt="Quote List" />
            <p>Quote List</p>
          </div>
          <div className="widget" onClick={handleMerchantMapClick}>
            <img src={mapIcon} alt="Merchant Map" />
            <p>Merchant Map</p>
          </div>
          <div className="widget" onClick={handleWarehouseSalesClick}>
            <img src={waresaleIcon} alt="Sales Warehouse" />
            <p>Sales Warehouse</p>
          </div>
          {userData && userData.internal && (
            <div className="widget" onClick={handleAllocationClick}>
              <img src={allocateIcon} alt="Internal Widget" />
              <p>Stock Allocation</p>
            </div>
          )}
          {userData && userData.sb && (
            <div className="widget" onClick={handleWareArch}>
              <img src={productIcon} alt="Product Reorder" />
              <p>Warehouse Archive</p>
            </div>
          )}
          {userData && userData.internal && (
            <div className="widget" onClick={handleReorderClick}>
              <img src={productIcon} alt="Product Reorder" />
              <p>Re-Order Stock</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SalesDashboard;
