import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { getDownloadURL, ref, listAll } from "firebase/storage";
import { storage } from '../firebase/firebaseConfig';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfPresentation = () => {
  const [currentPdf, setCurrentPdf] = useState(0);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [scale, setScale] = useState(1);

  useEffect(() => {
    const noticeRef = ref(storage, 'Notice');
    listAll(noticeRef)
      .then(async (res) => {
        const urls = await Promise.all(res.items.map((item) => getDownloadURL(item)));
        setPdfFiles(urls);
      })
      .catch((error) => {
        console.error("Error listing PDFs:", error);
      });
  }, []);

  useEffect(() => {
    const adjustScale = () => {
      const pdfViewer = document.getElementById('pdfViewer');
      if (!pdfViewer) return;

      const maxWidth = pdfViewer.clientWidth;
      const maxHeight = pdfViewer.clientHeight;

      // Assuming 600x800 as the base size of the PDF
      const scaleX = maxWidth / 250;
      const scaleY = maxHeight / 525;

      // Use the smaller scale to ensure the PDF page fits within the viewport
      const newScale = Math.min(scaleX, scaleY);
      setScale(newScale);
    };

    window.addEventListener('resize', adjustScale);
    adjustScale(); // Call at mount to set initial scale

    return () => window.removeEventListener('resize', adjustScale);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentPdf((prevPdf) => (prevPdf + 1) % pdfFiles.length);
    }, 10000); // Change to next PDF every 10 seconds

    return () => clearTimeout(timer);
  }, [currentPdf, pdfFiles.length]);

  return (
    <div id="pdfViewer" style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
      {pdfFiles.length > 0 && (
        <Document
          file={pdfFiles[currentPdf]}
          onLoadSuccess={({ numPages }) => console.log(`Loaded PDF with ${numPages} pages.`)}
        >
          <Page 
            pageNumber={1}
            scale={scale} // Apply the calculated scale
          />
        </Document>
      )}
    </div>
  );
};

export default PdfPresentation;
