import React, { useState, useEffect } from 'react';
import { getFirestore, collection, onSnapshot, doc, getDoc, getDocs, writeBatch } from 'firebase/firestore';
import './WarehouseView.css';
import FavouriteButton from './FavouriteButton';

const WarehouseDelete = () => {
  const [cylinders, setCylinders] = useState([]);
  const [viewMode, setViewMode] = useState('table');
  const [selectedIds, setSelectedIds] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const firestore = getFirestore();
    const fetchCode = async (productId) => {
      if (!productId) return 'N/A';
      const docRef = doc(firestore, "cyl_info", productId);
      const docSnap = await getDoc(docRef);
      return docSnap.exists() ? docSnap.data().productCode : 'N/A';
    };

    const fetchLocation = async (LocationId) => {
      if (LocationId.length < 5) return LocationId;
      const locationsRef = collection(firestore, "warehouse_locations");
      const querySnapshot = await getDocs(locationsRef);
      const uniqueId = LocationId.slice(-5);
      const matchingDoc = querySnapshot.docs.find(doc => doc.data().barcode.endsWith(uniqueId));
      return matchingDoc ? matchingDoc.id : LocationId;
    };

    const cylindersCollection = collection(firestore, "cylinders_warehouse");
    const unsubscribe = onSnapshot(cylindersCollection, async (querySnapshot) => {
      const cylindersData = await Promise.all(querySnapshot.docs.map(async (doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ProductCode: await fetchCode(data.ProductId),
          SerialNumber: data.SerialNumber,
          Special: data.Special,
          BookedInTime: data.BookedInTime,
          LocationId: await fetchLocation(data.LocationId),
          ProductId: data.ProductId
        };
      }));
      setCylinders(cylindersData);
    });

    return () => unsubscribe(); // Cleanup on unmount
  }, []);

  const getSpecialValueLabel = (specialValue) => {
    const specialValueMap = {
      '01': 'G1 - Male',
      '02': 'G1 - Female',
      '03': 'G4 - Male',
      '04': 'G4 - Female',
      '05': 'IDF2 - Male',
      '06': 'IDF2 - Female',
      '07': 'IDH1 - Male',
      '08': 'IDH1 - Female',
      '09': 'IDH2 - Male',
      '10': 'IDH2 - Female',
      '49': 'Standard',
      '56': 'None Standard',
      // Add more mappings here as needed
    };
  
    return specialValueMap[specialValue] || specialValue; // Return the original code if no mapping found
  };

  const handleSelectAll = (checked) => {
    setSelectedIds(checked ? cylinders.map(c => c.id) : []);
  };

  const handleSelectItem = (id, checked) => {
    setSelectedIds(prev => checked ? [...prev, id] : prev.filter(pid => pid !== id));
  };

  const deleteSelectedItems = async () => {
    const db = getFirestore();
    const batch = writeBatch(db);
    selectedIds.forEach(id => batch.delete(doc(db, "cylinders_warehouse", id)));
    try {
      await batch.commit();
      console.log('Selected items deleted');
      setSelectedIds([]);
    } catch (error) {
      console.error("Error deleting items: ", error);
    }
  };

  const formatDate = (timestamp) => timestamp ? new Date(timestamp.seconds * 1000).toLocaleString() : 'N/A';

  const filteredCylinders = cylinders.filter(cylinder =>
    cylinder.ProductCode.toLowerCase().includes(searchQuery.toLowerCase()) ||
    cylinder.SerialNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
    cylinder.LocationId.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <FavouriteButton pageUrl={window.location.pathname} />
      <div>
        <h1>Warehouse Stock</h1>
        <div className="controls-container">
          <button onClick={deleteSelectedItems} disabled={!selectedIds.length}>Delete Selected</button>
          <div className="search-container">
            <input
              type="text"
              placeholder="Search by Product Code, Serial Number or Location..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="search-input"
            />
          </div>
        </div>
        {viewMode === 'table' && (
          <table className="warehouse-table">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={selectedIds.length === filteredCylinders.length && cylinders.length > 0}
                    onChange={(e) => handleSelectAll(e.target.checked)}
                  />
                </th>
                <th>Product Code</th>
                <th>Serial Number</th>
                <th>Type</th>
                <th>Booked In</th>
                <th>Location</th>
                <th>Barcode</th>
              </tr>
            </thead>
            <tbody>
              {filteredCylinders.map(cylinder => (
                <tr key={cylinder.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedIds.includes(cylinder.id)}
                      onChange={(e) => handleSelectItem(cylinder.id, e.target.checked)}
                    />
                  </td>
                  <td>{cylinder.ProductCode}</td>
                  <td>{cylinder.SerialNumber || 'N/A'}</td>
                  <td>{getSpecialValueLabel(cylinder.Special)}</td>
                  <td>{formatDate(cylinder.BookedInTime)}</td>
                  <td>{cylinder.LocationId}</td>
                  <td>{`${cylinder.SerialNumber}${cylinder.Special}${cylinder.ProductId}`}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default WarehouseDelete;
