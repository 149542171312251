// TechnicalDashboard.jsx
import React from 'react';
import './Dashboard.css';
import unventedIcon from './assets/unvented.png';
import calcIcon from './assets/calculator-icon.png';
import { useNavigate } from 'react-router-dom';
import FavouriteButton from './FavouriteButton';

const TechnicalDashboard = () => {
  const navigate = useNavigate();


  const handleCalculatorClick = () => {
    navigate('/TechnicalCalculator');
  };

  const handleTechnicalClick = () => {
    navigate('/CylinderSelector');
  };
  
  return (
    <>
    <FavouriteButton pageUrl={window.location.pathname} />
    <div className="dashboard-container">
      <div className="dashboard-header">
        <h1>Technical Dashboard</h1>
        {/* You can add a logout or navigation buttons here if needed */}
      </div>

      <div className="widget-container">
        {/* Add widgets and content specific to the Warehouse Dashboard */}
        <div className="widget" onClick={handleTechnicalClick}>
                <img src={unventedIcon} alt="Book In" />
                <p>Cylinder Selector (Beta)</p>
                {/* Additional content for Helpdesk */}
              </div>
        <div className="widget" onClick={handleCalculatorClick}>
                <img src={calcIcon} alt="Book In" />
                <p>Calculators</p>
                {/* Additional content for Helpdesk */}
              </div>
        {/* Add more widgets as needed */}
      </div>
    </div>
  </>
  );
};

export default TechnicalDashboard;
