import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInUser } from '../../firebase/authServices';
import './LoginForm.css';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Hook for navigation

  const handleLogin = async (event) => {
    event.preventDefault();
    setError('');
    try {
      await signInUser(email, password);
      navigate('/Dashboard');
      // Handle successful login
    } catch (error) {
      console.error('Login error:', error);
      let errorMessage = 'An unexpected error occurred. Please try again.';
  
      switch (error.code) {
        case 'auth/invalid-email':
          errorMessage = 'Please enter a valid email address.';
          break;
        case 'auth/user-disabled':
          errorMessage = 'This account has been disabled.';
          break;
        case 'auth/user-not-found':
        case 'auth/wrong-password':
          // Using the same message for both cases for security
          errorMessage = 'Email or password is incorrect.';
          break;
        // Add more cases as needed
        default:
          break;
      }
  
      alert(errorMessage);
    }
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  return (
    <div className="login-container">
  <form className="login-form" onSubmit={handleLogin}>
    <h2>Login</h2>
    <input 
      type="email" 
      value={email} 
      onChange={(e) => setEmail(e.target.value)} 
      placeholder="Email" 
    />
    <input 
      type="password" 
      value={password} 
      onChange={(e) => setPassword(e.target.value)} 
      placeholder="Password" 
    />
    {error && <div className="error-message">{error}</div>} {/* Display the error message */}
    <button type="submit">Log In</button>
    <button type="button" onClick={handleForgotPassword}>Forgotten Password</button>
  </form>
</div>

  );
};

export default LoginForm;
