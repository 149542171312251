import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const { currentUser, userRoles } = useAuth();

  // Check if user is logged in
  if (!currentUser) {
    return <Navigate to="/" />;
  }

  // If allowedRoles are specified, check if user's role is included
  if (allowedRoles && !userRoles.some(role => allowedRoles.includes(role))) {
    return <Navigate to="/dashboard" />;
  }

  return children;
};

export default ProtectedRoute;


