import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, addDoc, updateDoc, getFirestore, Timestamp } from 'firebase/firestore';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './SignInAdmin.css';

const SignInAdmin = () => {
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [employees, setEmployees] = useState([]); // Stores employees of the selected department
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [date, setDate] = useState(new Date());
    const firestore = getFirestore();

    useEffect(() => {
        const fetchDepartments = async () => {
            const snapshot = await getDocs(collection(firestore, 'departments'));
            const departmentsData = snapshot.docs.map(doc => ({
                id: doc.id,
                name: doc.data().name,
            }));
            setDepartments(departmentsData);
        };
        fetchDepartments();
    }, [firestore]);

    useEffect(() => {
        const fetchEmployees = async (deptId) => {
            if (!deptId) return;
            const snapshot = await getDocs(collection(firestore, `departments/${deptId}/employees`));
            const employeesData = snapshot.docs.map(doc => ({
                id: doc.id,
                name: doc.data().name,
            }));
            setEmployees(employeesData);
        };

        if (selectedDepartment) {
            fetchEmployees(selectedDepartment.id);
        } else {
            setEmployees([]);
        }
    }, [selectedDepartment, firestore]);

    const handleEmployeeSelection = (employeeId) => {
        setSelectedEmployee(employeeId);
    };

    const handleSign = async (action) => {
        const timestamp = Timestamp.fromDate(date); // Converts Date to Firestore Timestamp
        const employeeRef = doc(firestore, `departments/${selectedDepartment.id}/employees/${selectedEmployee}`);
        const activityRef = collection(employeeRef, 'activity');

        await addDoc(activityRef, {
            timestamp,
            action
        });

        await updateDoc(employeeRef, {
            status: action === 'signed_in'
        });

        alert(`Employee has been ${action} at ${date.toLocaleString()}`);
        // Reset selection
        setSelectedDepartment(null);
        setSelectedEmployee(null);
        setEmployees([]);  // Clear employees list
    };

    return (
        <div className="SignInAdmin-container">
            <div className="SignInAdmin-header">
                <h1>Admin Sign-In and Sign-Out</h1>
            </div>
            <div className="SignInAdmin-form">
                <select onChange={(e) => setSelectedDepartment(departments.find(dept => dept.id === e.target.value))}>
                    <option value="">Select Department</option>
                    {departments.map(dept => (
                        <option key={dept.id} value={dept.id}>{dept.name}</option>
                    ))}
                </select>
                {selectedDepartment && (
                    <select onChange={(e) => handleEmployeeSelection(e.target.value)}>
                        <option value="">Select Employee</option>
                        {employees.map(emp => (
                            <option key={emp.id} value={emp.id}>{emp.name}</option>
                        ))}
                    </select>
                )}
                {selectedEmployee && (
                    <>
                        <DatePicker
                            selected={date}
                            onChange={setDate}
                            className="DateTimePicker"
                            showTimeSelect
                        />
                        <button onClick={() => handleSign('signed_in')}>Sign In</button>
                        <button onClick={() => handleSign('signed_out')}>Sign Out</button>
                    </>
                )}
            </div>
        </div>
    );
};

export default SignInAdmin;
