import React, { useEffect, useState } from 'react';
import { db } from '../firebase/firebaseConfig';
import { collection, getDocs, doc, updateDoc, increment, arrayUnion } from 'firebase/firestore';

const DataTable = () => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, 'manufacturingData'));
      setData(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchData();
  }, []);

  const printLabel = async (id, orderNo, productCode) => {
    const barcodeNumber = orderNo + productCode;
    const zpl = `
      ^XA
      ^FO50,50^BY2
      ^B3N,N,100,Y,N
      ^FD${barcodeNumber}^FS
      ^FO50,200^ADN,36,20
      ^FD${orderNo} - ${productCode}^FS
      ^XZ
    `;
    window.BrowserPrint.getDefaultDevice('printer', function(device) {
      device.send(zpl);
    }, function(error) {
      alert('Error: ' + error);
    });

    const docRef = doc(db, 'manufacturingData', id);
    await updateDoc(docRef, {
      timesPrinted: increment(1),
      printTimestamps: arrayUnion(new Date().toISOString())
    });
  };

  const filteredData = data.filter(item => {
    const orderNo = item.orderNo ? item.orderNo.toLowerCase() : '';
    const productCode = item.productCode ? item.productCode.toLowerCase() : '';
    const searchTermLower = searchTerm.toLowerCase();
    return orderNo.includes(searchTermLower) || productCode.includes(searchTermLower);
  });

  return (
    <div>
      <input 
        type="text" 
        placeholder="Search by Order No or Product Code" 
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <table>
        <thead>
          <tr>
            <th>Order No</th>
            <th>Product Code</th>
            <th>Quantity</th>
            <th>Times Printed</th>
            <th>Print</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => (
            <tr key={index}>
              <td>{item.orderNo}</td>
              <td>{item.productCode}</td>
              <td>{item.quantity}</td>
              <td>{item.timesPrinted}</td>
              <td><button onClick={() => printLabel(item.id, item.orderNo, item.productCode)}>Print</button></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
