import React, { useState, useEffect, useRef } from 'react';
import { getFirestore, collection, query, where, getDocs, addDoc, deleteDoc, doc, Timestamp } from 'firebase/firestore';
import './BookingOut.css';
import FavouriteButton from './FavouriteButton';

const WarehouseBookingOutForm = () => {
  const [barcode, setBarcode] = useState('');
  const [cylinders, setCylinders] = useState([]);
  const barcodeInputRef = useRef(null);

  useEffect(() => {
    barcodeInputRef.current.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const firestore = getFirestore();
    const serialNumber = barcode.slice(0, 6);
    const productId = barcode.slice(8);

    try {
      const cylindersCollection = collection(firestore, "cylinders_warehouse");
      const q = query(cylindersCollection, where("SerialNumber", "==", serialNumber), where("ProductId", "==", productId));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("No matching document found");
        setCylinders([]);
        return;
      }

      const docs = querySnapshot.docs;

      if (docs.length > 1) {
        const allSameLocation = docs.every(cyl => cyl.data().LocationId === docs[0].data().LocationId);
        if (allSameLocation) {
          bookOutCylinder(docs[0]);
          setCylinders([]); // Clear after booking out automatically
        } else {
          console.log("Multiple cylinders found in different locations, please select one to book out.");
          setCylinders(docs); // Set the cylinders for user selection
        }
      } else {
        bookOutCylinder(docs[0]);
        setCylinders([]); // Clear after booking out automatically
      }
    } catch (error) {
      console.error("Error in finding cylinders: ", error);
      setCylinders([]);
    } finally {
      setBarcode(''); // Reset barcode after processing
      barcodeInputRef.current.focus(); // Refocus on the barcode input
    }
  };

  const bookOutCylinder = async (cylinderSnapshot) => {
    const firestore = getFirestore();
    const archiveData = {
      ...cylinderSnapshot.data(),
      BookedOutTime: Timestamp.now(),
    };

    await addDoc(collection(firestore, 'warehouse_archive'), archiveData);
    await deleteDoc(doc(firestore, "cylinders_warehouse", cylinderSnapshot.id));
    console.log("Cylinder booked out and archived");
    setCylinders([]); // Clear cylinders after successful booking
    setBarcode(''); // Reset barcode after processing
    barcodeInputRef.current.focus(); // Refocus on the barcode input
  };

  const handleSelection = (id) => {
    const selectedSnapshot = cylinders.find(cylinder => cylinder.id === id);
    if (selectedSnapshot) {
      bookOutCylinder(selectedSnapshot);
    }
  };

  return (
    <>
      <FavouriteButton pageUrl={window.location.pathname} />
      <div className="BookInbody">
        <div className="bookincontainer">
          <h1>Booking Out:</h1>
          <form onSubmit={handleSubmit}>
            <label htmlFor="barcode">Scan or Enter Barcode:</label>
            <input
              type="text"
              id="barcode"
              name="barcode"
              required
              value={barcode}
              onChange={e => setBarcode(e.target.value)}
              ref={barcodeInputRef}
            />
            <button type="submit">Submit</button>
          </form>
          {cylinders.length > 0 && (
            <div>
              <h2>Select a cylinder to book out:</h2>
              {cylinders.map(cylinder => (
                <div key={cylinder.id}>
                  <button onClick={() => handleSelection(cylinder.id)}>
                    Book Out - {cylinder.data().LocationId} - {cylinder.data().SerialNumber}
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WarehouseBookingOutForm;
