// EmailTestComponent.jsx
import React, { useState } from 'react';
import axios from 'axios';

const EmailTestComponent = () => {
  const [to, setTo] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState('');

  const handleSendEmail = async () => {
    try {
      const res = await axios.post('https://europe-west2-peters-help-desk.cloudfunctions.net/sendEmail', {
        to,
        subject,
        text: message,
      });
      setResponse('Email sent successfully!');
    } catch (error) {
      setResponse(`Error sending email: ${error.message}`);
    }
  };

  return (
    <div className="email-test-container">
      <h2>Send Test Email</h2>
      <div className="form-group">
        <label>To:</label>
        <input
          type="email"
          value={to}
          onChange={(e) => setTo(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Subject:</label>
        <input
          type="text"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Message:</label>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
      </div>
      <button onClick={handleSendEmail}>Send Email</button>
      {response && <p>{response}</p>}
    </div>
  );
};

export default EmailTestComponent;
