import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc, getFirestore } from 'firebase/firestore';
import './EditCylinderModal.css';

const EditDocumentModal = ({ documentData, onSaveSuccess, onClose }) => {
  const [bomCreated, setBomCreated] = useState(documentData?.bomCreated || false);
  const [bomBox, setBomBox] = useState(documentData?.bomBox);
  const [commentsBox, setCommentsBox] = useState(documentData?.commentsBox);
  const [customerApproved, setCustomerApproved] = useState(documentData?.customerApproved);
  const [customerEmail, setCustomerEmail] = useState(documentData?.customerEmail);
  const [customerEmailed, setCustomerEmailed] = useState(documentData?.customerEmailed);
  const [customerName, setCustomerName] = useState(documentData?.customerName);
  const [serialNumber, setSerialNumber] = useState(documentData?.serialNumber);
  const [sketchCreated, setSketchCreated] = useState(documentData?.sketchCreated);
  const [orderChecked, setOrderChecked] = useState(documentData?.orderChecked);
  const [programComplete, setProgramComplete] = useState(documentData?.programComplete);
  const [readyForProduction, setReadyForProduction] = useState(documentData?.readyForProduction);
  const [newFiles, setNewFiles] = useState([]);
  const storage = getStorage();
  const firestore = getFirestore();

  const generateDownloadURL = async (filePath) => {
    const storageRef = ref(storage, filePath);
    try {
      const url = await getDownloadURL(storageRef);
      window.open(url, "_blank"); // Open the URL in a new tab
    } catch (error) {
      console.error("Error fetching file download URL", error);
      alert("Error fetching file download URL");
    }
  };

  const onDrop = useCallback(acceptedFiles => {
    setNewFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const db = getFirestore();

  const saveChanges = async () => {

    if (newFiles.length > 0) {
      const uploadTasks = newFiles.map(file => {
        const storageRef = ref(storage, `uploads/${file.name}`);
        return uploadBytes(storageRef, file).then(snapshot => snapshot.metadata.fullPath);
      });

      const paths = await Promise.all(uploadTasks);
      const updatedFiles = documentData.files ? [...documentData.files, ...paths] : [...paths];

      // Update Firestore document with new file references
      const docRef = doc(firestore, "files", documentData.id);
      await updateDoc(docRef, {
        ...documentData, // Spread existing fields
        files: updatedFiles, // Update with new file paths
      });
    }

    const docRef = doc(db, "files", documentData.id);
    await updateDoc(docRef, {
      bomCreated, // checkbox
      bomBox, // text box
      commentsBox, // text box
      customerApproved, // check box
      customerEmail, // text box
      customerEmailed, // check box
      customerName, // label
      orderChecked, // check box
      programComplete, // check box
      readyForProduction, // check box
      serialNumber, // label
      sketchCreated // check box

    });

    if (onSaveSuccess) {
      onSaveSuccess();
  }

    onClose();
  };
  console.log(documentData);

  return (
    <div className="modal">
      <div className="modal-content">
      <h2>Edit Document</h2>
      <label>
        SO:
        <input type="text" value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} disabled />
      </label>
      <label>
        Customer:
        <input type="text" value={customerName} onChange={(e) => setCustomerName(e.target.value)} disabled />
      </label>
      <label>
        Comments:
        <textarea value={commentsBox} onChange={(e) => setCommentsBox(e.target.value)} />
      </label>
      <label>
        Sketch Created:
        <input type="checkbox" checked={sketchCreated} onChange={(e) => setSketchCreated(e.target.checked)} />
      </label>
      <label>
        Bom Created:
        <input type="checkbox" checked={bomCreated} onChange={(e) => setBomCreated(e.target.checked)} />
      </label>
      <label>
        Bom Box:
        <input type="text" value={bomBox} onChange={(e) => setBomBox(e.target.value)} />
      </label>
      <label>
        Order Checked:
        <input type="checkbox" checked={orderChecked} onChange={(e) => setOrderChecked(e.target.checked)} />
      </label>
      <label>
        Customer Emailed:
        <input type="checkbox" checked={customerEmailed} onChange={(e) => setCustomerEmailed(e.target.checked)} />
      </label>
      <label>
        Customer Email:
        <input type="text" value={customerEmail} onChange={(e) => setCustomerEmail(e.target.value)} />
      </label>
      <label>
        Customer Approved:
        <input type="checkbox" checked={customerApproved} onChange={(e) => setCustomerApproved(e.target.checked)} />
      </label>
      <label>
        Program Complete:
        <input type="checkbox" checked={programComplete} onChange={(e) => setProgramComplete(e.target.checked)} />
      </label>
      <label>
        Ready For Production:
        <input type="checkbox" checked={readyForProduction} onChange={(e) => setReadyForProduction(e.target.checked)} />
      </label>
      <div>

  <h3>Files</h3>
  <ul className="file-list">
    {documentData.files?.map((file, index) => (
      <li key={index} onClick={() => generateDownloadURL(file)}>
        {file.split('/').pop()}
      </li>
    ))}
  </ul>
</div>
<br />
        
        {/* Dropzone for new files */}
        <div {...getRootProps()} style={{border: '2px dashed #007bff', padding: '20px', cursor: 'pointer'}}>
          <input {...getInputProps()} />
          {isDragActive ? <p>Drop the files here ...</p> : <p>Click here or drag files to upload.</p>}
        </div>
        <ul>
          {newFiles.map((file, index) => (
            <li key={index}>{file.name}</li>
          ))}
        </ul>
      {/* Additional fields should be added here if necessary */}
      <div style={{ marginTop: '20px' }}>
        <button onClick={saveChanges} style={{ marginRight: '10px' }}>Save</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  </div>
  );
};  

export default EditDocumentModal;
