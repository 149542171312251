import React from 'react';
import { db } from '../firebase/firebaseConfig';
import { collection, getDocs, deleteDoc, doc, writeBatch } from 'firebase/firestore';

const DeleteAllManufacturingData = () => {
  const deleteAllDocuments = async () => {
    const querySnapshot = await getDocs(collection(db, 'manufacturingData'));
    const batch = writeBatch(db);

    querySnapshot.forEach((document) => {
      batch.delete(doc(db, 'manufacturingData', document.id));
    });

    await batch.commit();
    alert('All entries in manufacturingData have been deleted.');
  };

  return (
    <div>
      <button onClick={deleteAllDocuments}>Delete All Entries in Manufacturing Data</button>
    </div>
  );
};

export default DeleteAllManufacturingData;
