import React, { useState, useEffect, useRef } from 'react';
import { getFirestore, collection, query, where, getDocs, addDoc, deleteDoc, Timestamp, doc } from 'firebase/firestore';
import './WarehouseView.css';
import FavouriteButton from './FavouriteButton';

const LoadListManager = () => {
  const [barcode, setBarcode] = useState('');
  const [loadListName, setLoadListName] = useState('');
  const [loadList, setLoadList] = useState([]);
  const [loadLists, setLoadLists] = useState([]);
  const barcodeInputRef = useRef(null);

  useEffect(() => {
    barcodeInputRef.current.focus();
  }, []);

  const handleAddToLoadList = async (e) => {
    e.preventDefault();
    const firestore = getFirestore();
    const serialNumber = barcode.slice(0, 6);
    const productId = barcode.slice(8);

    try {
      const cylindersCollection = collection(firestore, "cylinders_warehouse");
      const q = query(cylindersCollection, where("SerialNumber", "==", serialNumber), where("ProductId", "==", productId));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("No matching document found");
        return;
      }

      const cylinder = querySnapshot.docs[0];
      setLoadList(prevList => [...prevList, cylinder]);
    } catch (error) {
      console.error("Error in finding cylinders: ", error);
    } finally {
      setBarcode(''); // Reset barcode after processing
      barcodeInputRef.current.focus(); // Refocus on the barcode input
    }
  };

  const handleSaveLoadList = async () => {
    const firestore = getFirestore();
    const loadListData = {
      name: loadListName,
      items: loadList.map(cyl => cyl.data()),
      createdAt: Timestamp.now(),
    };

    const docRef = await addDoc(collection(firestore, 'load_lists'), loadListData);

    for (let cylinder of loadList) {
      await deleteDoc(doc(firestore, "cylinders_warehouse", cylinder.id));
    }

    setLoadLists(prevLists => [...prevLists, { id: docRef.id, ...loadListData }]);
    setLoadListName('');
    setLoadList([]);
    console.log("Load list saved and items moved to load_lists");
  };

  return (
    <>
      <FavouriteButton pageUrl={window.location.pathname} />
      <div className="LoadListManagerBody">
        <div className="loadlistcontainer">
          <h1>Create Load List:</h1>
          <form onSubmit={handleAddToLoadList}>
            <label htmlFor="loadListName">Load List Name:</label>
            <input
              type="text"
              id="loadListName"
              name="loadListName"
              required
              value={loadListName}
              onChange={e => setLoadListName(e.target.value)}
            />
            <label htmlFor="barcode">Scan or Enter Barcode:</label>
            <input
              type="text"
              id="barcode"
              name="barcode"
              required
              value={barcode}
              onChange={e => setBarcode(e.target.value)}
              ref={barcodeInputRef}
            />
            <button type="submit">Add to Load List</button>
          </form>
          <button onClick={handleSaveLoadList}>Save Load List</button>
          <div>
            <h2>Current Load List:</h2>
            {loadList.map((cylinder, index) => (
              <div key={index}>
                {cylinder.data().SerialNumber} - {cylinder.data().ProductId}
              </div>
            ))}
          </div>
        </div>
        <div className="loadlistscontainer">
          <h2>Saved Load Lists:</h2>
          {loadLists.map(loadList => (
            <div key={loadList.id}>
              <h3>{loadList.name}</h3>
              {loadList.items.map((item, index) => (
                <div key={index}>
                  {item.SerialNumber} - {item.ProductId}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default LoadListManager;
