// SketchDashboard.jsx
import React from 'react';
import './Dashboard.css';
import punchIcon from './assets/house-turret.png';
import punchviewIcon from './assets/punchview.png';
import uploadIcon from './assets/upload.png';
import barcodeIcon from './assets/barcode.png';
import { useNavigate } from 'react-router-dom';
import FavouriteButton from './FavouriteButton';

const FactoryDashboard = () => {
  const navigate = useNavigate();


  const handleCSVClick = () => {
    navigate('/CSVUploader');
  };

  const handleDataClick = () => {
    navigate('/DataTable');
  };

  const handlePunchClick = () => {
    navigate('/StainlessPunch');
  };

  const handlePunchViewClick = () => {
    navigate('/StainlessPunchView');
  };
  
  return (
    <>
    <FavouriteButton pageUrl={window.location.pathname} />
    <div className="dashboard-container">
      <div className="dashboard-header">
        <h1>Factory Dashboard</h1>
        {/* You can add a logout or navigation buttons here if needed */}
      </div>
      <div className="widget-container">
        {/* Add widgets and content specific to the Warehouse Dashboard */}
        <div className="widget" onClick={handleCSVClick}>
                <img src={uploadIcon} alt="Book In" />
                <p>CSV Uploader</p>
                {/* Additional content for Helpdesk */}
              </div>
        <div className="widget" onClick={handleDataClick}>
                <img src={barcodeIcon} alt="Book In" />
                <p>Punch Print</p>
                {/* Additional content for Helpdesk */}
              </div>
        {/* Add widgets and content specific to the Warehouse Dashboard */}
        <div className="widget" onClick={handlePunchClick}>
                <img src={punchIcon} alt="Book In" />
                <p>Punch Entry</p>
                {/* Additional content for Helpdesk */}
              </div>
        <div className="widget" onClick={handlePunchViewClick}>
                <img src={punchviewIcon} alt="Book In" />
                <p>View Punch Entries</p>
                {/* Additional content for Helpdesk */}
              </div>
        {/* Add more widgets as needed */}
      </div>
    </div>
  </>
  );
};

export default FactoryDashboard;
