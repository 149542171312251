import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs, addDoc, serverTimestamp, doc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase/firebaseConfig';
import { getAuth } from 'firebase/auth';
import SignatureCanvas from 'react-signature-canvas';

const DeliveryUpload = () => {
  const [products, setProducts] = useState([]);
  const [barcode, setBarcode] = useState('');
  const [image, setImage] = useState(null);
  const [location, setLocation] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [locationFetched, setLocationFetched] = useState(false);
  const [userName, setUserName] = useState(''); // State to store the user's name from Firestore
  const [signerName, setSignerName] = useState(''); // State to store the name entered by the signer
  const [signatureData, setSignatureData] = useState('');
  const [showSignaturePad, setShowSignaturePad] = useState(false);

  const auth = getAuth();
  const sigCanvasRef = useRef();

  // Fetch user data from Firestore
  useEffect(() => {
    const fetchUserData = async () => {
      const userEmail = auth.currentUser?.email;
      if (userEmail) {
        const userDocRef = doc(db, 'Users', userEmail);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserName(userData.name); // Set the user's name from Firestore
        } else {
          console.log('No user data found!');
        }
      }
    };

    fetchUserData();
  }, [auth.currentUser]);

  // Fetch existing products from Firestore
  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, 'cylinders_warehouse'));
      const productData = querySnapshot.docs.map((doc) => {
        const { SerialNumber, Special, ProductId } = doc.data();
        return {
          id: doc.id,
          barcode: `${SerialNumber}${Special}${ProductId}`,
        };
      });
      setProducts(productData);
    };

    fetchProducts();
  }, []);

  // Check for a barcode in the URL and set it to the state
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const barcodeFromUrl = urlParams.get('barcode');
    if (barcodeFromUrl) {
      setBarcode(barcodeFromUrl); // Set barcode from URL if present
    }
  }, []);

  // Handle image upload
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  // Handle location fetch with improved error handling
  const fetchLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setLocationFetched(true);
        },
        (error) => {
          console.error('Error fetching location: ', error);
          switch (error.code) {
            case error.PERMISSION_DENIED:
              alert('User denied the request for Geolocation.');
              break;
            case error.POSITION_UNAVAILABLE:
              alert('Location information is unavailable.');
              break;
            case error.TIMEOUT:
              alert('The request to get user location timed out.');
              break;
            case error.UNKNOWN_ERROR:
              alert('An unknown error occurred.');
              break;
            default:
              alert('An error occurred while fetching the location.');
          }
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);

    const validBarcode = products.find((product) => product.barcode === barcode);
    if (!validBarcode) {
      alert('Invalid barcode. Please enter a valid barcode.');
      setUploading(false);
      return;
    }

    let imageUrl = '';
    if (image) {
      const timestamp = new Date().toISOString();
      const imageName = `${barcode}_${timestamp}_${image.name}`;
      const storageRef = ref(storage, `deliveries/${imageName}`);
      await uploadBytes(storageRef, image);
      imageUrl = await getDownloadURL(storageRef);
    }

    try {
      await addDoc(collection(db, 'deliveries'), {
        barcode,
        image: imageUrl,
        location,
        userName,
        signerName, // Include the signer's name in the submission
        signature: signatureData,
        timestamp: serverTimestamp(),
      });
      alert('Delivery information uploaded successfully.');

      // Reset the form after successful submission
      setBarcode('');
      setImage(null);
      setLocation(null);
      setSignerName('');
      setSignatureData('');
      setLocationFetched(false);
    } catch (error) {
      console.error('Error uploading delivery information: ', error);
      alert('Failed to upload delivery information.');
    }

    setUploading(false);
  };

  // Save the signature as an image URL
  const saveSignature = () => {
    setSignatureData(sigCanvasRef.current.getTrimmedCanvas().toDataURL('image/png'));
    setShowSignaturePad(false);
  };

  // Check if all fields are filled
  const isFormComplete = barcode && image && location && signatureData && signerName;

  return (
    <div>
      <h1>Upload Delivery Information</h1>
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: '15px' }}>
          <label>Enter Barcode:</label>
          <input
            type="text"
            value={barcode}
            onChange={(e) => setBarcode(e.target.value)}
            list="barcode-list"
            required
          />
          <datalist id="barcode-list">
            {products.map((product) => (
              <option key={product.id} value={product.barcode} />
            ))}
          </datalist>
        </div>
        <div style={{ marginBottom: '15px' }}>
          <label>Upload Image:</label>
          <input
            type="file"
            accept="image/*"
            capture="environment"
            onChange={handleImageChange}
            required
          />
        </div>
        <div style={{ marginBottom: '15px' }}>
          <button 
            type="button" 
            onClick={fetchLocation} 
            disabled={locationFetched}
          >
            {locationFetched ? 'Location Fetched' : 'Fetch Current Location'}
          </button>
          {locationFetched && (
            <p>
              Located Successfully.
            </p>
          )}
        </div>
        <div style={{ marginBottom: '15px' }}>
          <label>Print Customer Name</label>
          <input
            type="text"
            value={signerName}
            onChange={(e) => setSignerName(e.target.value)}
            placeholder="Enter customer name here..."
            required
          />
        </div>
        <div style={{ marginBottom: '15px' }}>
          <button type="button" onClick={() => setShowSignaturePad(true)}>
            {signatureData ? 'Signature Saved' : 'Sign Here'}
          </button>
          {signatureData && <p>Signature captured successfully.</p>}
        </div>
        {showSignaturePad && (
          <div>
            <SignatureCanvas
              ref={sigCanvasRef}
              penColor="black"
              canvasProps={{
                width: 500,
                height: 200,
                className: 'sigCanvas',
                style: { border: '2px solid #0056b3', backgroundColor: '#e0f7fa' }
              }}
            />
            <br />
            <br />
            <button type="button" onClick={saveSignature}>Save Signature</button>
            <button type="button" onClick={() => sigCanvasRef.current.clear()}>Clear</button>
            
          </div>
          
        )}
        <br />
        <div style={{ marginBottom: '15px' }}>
        
          <button type="submit" disabled={uploading || !isFormComplete}>
            {uploading ? 'Uploading...' : 'Submit'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default DeliveryUpload;
