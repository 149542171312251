// UploadExcel.js
import React, { useState } from 'react';
import * as xlsx from 'xlsx';
import { db } from '../firebase/firebaseConfig';
import { doc, setDoc, collection } from 'firebase/firestore';

const UploadExcel = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      setMessage('Please select a file first.');
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = xlsx.read(data, { type: 'array' });

        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const sheetData = xlsx.utils.sheet_to_json(sheet);

        for (const row of sheetData) {
          const { Subcollection1, Subcollection2, Subcollection3, Label1, Value1 } = row;

          // Enhanced logging for debugging
          console.log(`Processing row: ${JSON.stringify(row)}`);
          if (Subcollection1 && Subcollection2 && Subcollection3 && Label1 && Value1) {
            // Create the document reference in the hardcoded collection 'newproducts'
            const docRef = doc(db, 'newproducts', Subcollection1);

            // Create the sub-collection reference for Subcollection2
            const subCollection2Ref = doc(collection(docRef, Subcollection2), Subcollection2);

            // Create the sub-collection reference for Subcollection3
            const subCollection3Ref = collection(subCollection2Ref, Subcollection3);

            // Create the document with the label and value
            const dataToUpload = { [Label1]: Value1 };

            await setDoc(doc(subCollection3Ref, Label1), dataToUpload);
          } else {
            console.error('Skipping row due to missing required fields:', row);
          }
        }

        setMessage('Data successfully uploaded to Firestore');
      } catch (error) {
        console.error('Error uploading data: ', error);
        setMessage('Error uploading data');
      }
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <div>
      <h2>Upload Excel File</h2>
      <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
      <p>{message}</p>
    </div>
  );
};

export default UploadExcel;
