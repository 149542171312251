import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { db } from '../firebase/firebaseConfig';
import { collection, addDoc, query, where, getDocs, doc, setDoc } from 'firebase/firestore';
import * as XLSX from 'xlsx';

const CSVUploader = () => {
  const [files, setFiles] = useState([]);
  const [newProducts, setNewProducts] = useState([]);
  const [descriptions, setDescriptions] = useState({});
  const [usedNumbers, setUsedNumbers] = useState(new Set());

  useEffect(() => {
    const fetchUsedNumbers = async () => {
      const querySnapshot = await getDocs(collection(db, 'cyl_info'));
      const numbers = new Set();
      querySnapshot.forEach(doc => {
        numbers.add(parseInt(doc.id, 10));
      });
      setUsedNumbers(numbers);
    };

    fetchUsedNumbers();
  }, []);

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  const handleDescriptionChange = (event, productCode) => {
    setDescriptions({
      ...descriptions,
      [productCode]: event.target.value
    });
  };

  const generateBarcodeNumber = (usedNumbers) => {
    let number = 50000;
    while (true) {
      number++;
      if (!usedNumbers.has(number)) {
        usedNumbers.add(number);
        return number.toString();
      }
    }
  };

  const uploadFiles = async () => {
    const processedProductCodes = new Set();
    const newProductsArray = [];
    for (const file of files) {
      Papa.parse(file, {
        complete: async function(results) {
          for (const row of results.data) {
            if (row.length < 7) {
              console.error("Row doesn't have enough columns:", row);
              continue;
            }
            
            const orderNo = row[3] ? row[3].replace('SO', '') : null;
            const productCode = row[4] ? row[4].replace('"', '') : null;
            const quantity = parseInt(row[6], 10);
            if (!productCode || !orderNo || isNaN(quantity)) {
              console.error("Invalid data:", row);
              continue;
            }

            if (processedProductCodes.has(productCode)) {
              continue;
            }
            processedProductCodes.add(productCode);

            const q = query(collection(db, 'cyl_info'), where('productCode', '==', productCode));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
              const newBarcodeNumber = generateBarcodeNumber(usedNumbers);
              newProductsArray.push({
                productCode: productCode,
                barcodeNumber: newBarcodeNumber
              });
            }

            await addDoc(collection(db, 'manufacturingData'), {
              orderNo: orderNo,
              productCode: productCode,
              quantity: quantity,
              timesPrinted: 0,
              printTimestamps: [],
              timestamp: new Date(),
              rollertimestamp: []
            });
          }
          setNewProducts(prevNewProducts => [...prevNewProducts, ...newProductsArray]);
          generateExcel(newProductsArray); // Generate Excel file after processing
        },
        error: function(error) {
          console.log("Error processing file:", error);
        }
      });
    }
  };

  const deleteProduct = (index) => {
    setNewProducts(newProducts.filter((_, i) => i !== index));
  };

  const saveNewProducts = async () => {
    for (const product of newProducts) {
      const { productCode, barcodeNumber } = product;
      const productDescription = descriptions[productCode] || '';
      await setDoc(doc(db, 'cyl_info', barcodeNumber), {
        productCode: productCode,
        productDescription: productDescription
      });
    }
    setNewProducts([]);
  };

  const generateExcel = (products) => {
    const data = products.map(product => ({
      FormattedOutput: `"${product.productCode}": "49${product.barcodeNumber}",`
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'New Products');

    XLSX.writeFile(workbook, 'NewProducts.xlsx');
  };

  return (
    <div>
      <input type="file" accept=".csv" multiple onChange={handleFileChange} />
      <button onClick={uploadFiles}>Upload and Process Files</button>

      {newProducts.length > 0 && (
        <div>
          <h3>Enter Descriptions for New Products</h3>
          {newProducts.map((product, index) => (
            <div key={index}>
              <p>Product Code: {product.productCode}</p>
              <p>Generated Barcode: {product.barcodeNumber}</p>
              <input
                type="text"
                placeholder="Enter product description"
                value={descriptions[product.productCode] || ''}
                onChange={(e) => handleDescriptionChange(e, product.productCode)}
              />
              <button onClick={() => deleteProduct(index)}>Delete</button>
            </div>
          ))}
          <button onClick={saveNewProducts}>Save New Products</button>
          <button onClick={() => generateExcel(newProducts)}>Generate Excel Again</button>
        </div>
      )}
    </div>
  );
};

export default CSVUploader;
