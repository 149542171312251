import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, getDoc, doc } from 'firebase/firestore';
import './ReorderTable.css';

const ReorderTable = () => {
  const [cylinders, setCylinders] = useState([]);
  const [aggregatedData, setAggregatedData] = useState([]);

  const monitoredProducts = {
    '50009': 10, // Example: product code '50009' with minimum quantity of 10
    // Add more product codes and their minimum quantities as needed
  };

  useEffect(() => {
    const fetchData = async () => {
      const firestore = getFirestore();
      const cylindersCollection = collection(firestore, 'cylinders_warehouse');
      const querySnapshot = await getDocs(cylindersCollection);

      const cylindersData = await Promise.all(
        querySnapshot.docs.map(async (docSnapshot) => {
          const cylinderData = docSnapshot.data();
          return {
            id: docSnapshot.id,
            ProductCode: await fetchCode(cylinderData.ProductId),
            SerialNumber: cylinderData.SerialNumber,
            Special: cylinderData.Special,
            BookedInTime: cylinderData.BookedInTime,
            LocationId: await fetchLocation(cylinderData.LocationId),
            ProductId: cylinderData.ProductId,
            Stock: cylinderData.Stock ? 'STOCK' : 'ORDER',
            Allocated: cylinderData.Allocated || false,
          };
        })
      );

      setCylinders(cylindersData);
    };

    const fetchCode = async (productId) => {
      const firestore = getFirestore();
      if (!productId || typeof productId !== 'string') {
        return 'N/A';
      }
      const docRef = doc(firestore, 'cyl_info', productId);
      const docSnap = await getDoc(docRef);
      return docSnap.exists() ? docSnap.data().productCode : 'N/A';
    };

    const fetchLocation = async (LocationId) => {
      const firestore = getFirestore();
      if (LocationId.length < 5) return LocationId;
      const locationsRef = collection(firestore, 'warehouse_locations');
      const querySnapshot = await getDocs(locationsRef);
      const uniqueId = LocationId.slice(-5);
      const matchingDoc = querySnapshot.docs.find((doc) => doc.data().barcode.endsWith(uniqueId));
      return matchingDoc ? matchingDoc.id : LocationId;
    };

    fetchData();
  }, []);

  useEffect(() => {
    const aggregateData = () => {
      const aggregated = cylinders.reduce((acc, cylinder) => {
        const code = cylinder.ProductCode;
        if (monitoredProducts.hasOwnProperty(code)) {
          if (!acc[code]) {
            acc[code] = { count: 0, allocated: 0, minimumQty: monitoredProducts[code] };
          }
          acc[code].count += 1;
          if (cylinder.Allocated) {
            acc[code].allocated += 1;
          }
        }
        return acc;
      }, {});

      const result = Object.entries(aggregated).map(([code, data]) => ({
        ProductCode: code,
        Count: data.count,
        Allocated: data.allocated,
        MinimumQty: data.minimumQty,
      }));

      setAggregatedData(result);
    };

    if (cylinders.length > 0) {
      aggregateData();
    }
  }, [cylinders, monitoredProducts]);

  const getRowClass = (count, minimumQty, allocated) => {
    if (count < minimumQty || count - allocated < minimumQty) {
      return 'row-low-stock';
    }
    return '';
  };

  return (
    <div>
      <h1>Reorder List</h1>
      <table className='reorder-table'>
        <thead>
          <tr>
            <th>Product Code</th>
            <th>Count</th>
            <th>Minimum Qty</th>
            <th>Allocated</th>
          </tr>
        </thead>
        <tbody>
          {aggregatedData.map((product) => (
            <tr key={product.ProductCode} className={getRowClass(product.Count, product.MinimumQty, product.Allocated)}>
              <td>{product.ProductCode}</td>
              <td>{product.Count}</td>
              <td>{product.MinimumQty}</td>
              <td>{product.Allocated}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReorderTable;
