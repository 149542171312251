import React, { useState, useEffect } from 'react';
import {
  getFirestore,
  collection,
  updateDoc,
  doc,
  getDoc,
  getDocs,
} from 'firebase/firestore';
import './WarehouseView.css'; // Reusing the CSS for consistency
import FavouriteButton from './FavouriteButton';

const WarehouseLocationUpdateForm = () => {
  const [cylinders, setCylinders] = useState([]);
  const [filteredCylinders, setFilteredCylinders] = useState([]);
  const [selectedCylinder, setSelectedCylinder] = useState(null);
  const [newLocation, setNewLocation] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const firestore = getFirestore();

  const fetchCode = async (productId) => {
    if (!productId || typeof productId !== 'string') {
      return 'N/A';
    }
    const docRef = doc(firestore, 'cyl_info', productId);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data().productCode : 'N/A';
  };

  const fetchLocation = async (LocationId) => {
    if (LocationId.length < 5) return LocationId;
    const locationsRef = collection(firestore, 'warehouse_locations');
    const querySnapshot = await getDocs(locationsRef);
    const uniqueId = LocationId.slice(-5);
    const matchingDoc = querySnapshot.docs.find((doc) => doc.data().barcode.endsWith(uniqueId));
    return matchingDoc ? matchingDoc.id : LocationId;
  };

  useEffect(() => {
    const fetchData = async () => {
      const cylindersCollection = collection(firestore, 'cylinders_warehouse');
      const querySnapshot = await getDocs(cylindersCollection);

      const loadedCylinders = await Promise.all(
        querySnapshot.docs.map(async (docSnapshot) => {
          const data = docSnapshot.data();
          const productCode = await fetchCode(data.ProductId); // Fetch product code asynchronously
          const locationId = await fetchLocation(data.LocationId); // Fetch location asynchronously
          return {
            id: docSnapshot.id,
            ProductCode: productCode,
            SerialNumber: data.SerialNumber,
            LocationId: locationId,
            ...data,
          };
        })
      );

      setCylinders(loadedCylinders);
    };

    fetchData();
  }, [firestore]);

  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = cylinders.filter(
      (cylinder) =>
        cylinder.ProductCode.toLowerCase().includes(lowerCaseQuery) ||
        cylinder.SerialNumber.toLowerCase().includes(lowerCaseQuery) ||
        cylinder.LocationId.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredCylinders(filtered);
  }, [searchQuery, cylinders]);

  const handleMoveClick = (cylinder) => {
    setSelectedCylinder(cylinder);
  };

  const handleLocationChange = (event) => {
    setNewLocation(event.target.value);
  };

  const confirmMove = async () => {
    if (selectedCylinder && newLocation) {
      const cylinderRef = doc(firestore, 'cylinders_warehouse', selectedCylinder.id);
      await updateDoc(cylinderRef, { LocationId: newLocation });
      setNewLocation('');
      setSelectedCylinder(null); // Reset after update

      // Update the local state to reflect the changes
      setCylinders((prevCylinders) =>
        prevCylinders.map((cyl) =>
          cyl.id === selectedCylinder.id ? { ...cyl, LocationId: newLocation } : cyl
        )
      );
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      <FavouriteButton pageUrl={window.location.pathname} />
      <h1>Move Cylinder</h1>
      <div>
        <input
          type='text'
          placeholder='Search by Product Code, Serial Number, or Location...'
          value={searchQuery}
          onChange={handleSearchChange}
          className='search-input'
        />
      </div>
      <table className='warehouse-table'>
        <thead>
          <tr>
            <th>Product Code</th>
            <th>Serial Number</th>
            <th>Current Location</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredCylinders.map((cylinder) => (
            <tr key={cylinder.id}>
              <td>{cylinder.ProductCode}</td>
              <td>{cylinder.SerialNumber}</td>
              <td>{cylinder.LocationId}</td>
              <td>
                {selectedCylinder?.id === cylinder.id ? (
                  <>
                    <input
                      type='text'
                      value={newLocation}
                      onChange={handleLocationChange}
                      placeholder='New Location Barcode'
                    />
                    <button onClick={confirmMove}>Confirm Move</button>
                  </>
                ) : (
                  <button onClick={() => handleMoveClick(cylinder)}>Move</button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default WarehouseLocationUpdateForm;
