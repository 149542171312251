import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import mapIcon from './assets/map-icon.png'

const customIcon = new L.Icon({
    iconUrl: mapIcon,
    iconSize: [35, 35], // Size of the icon
    iconAnchor: [12, 41], // Point of the icon which will correspond to marker's location
    popupAnchor: [1, -34], // Point from which the popup should open relative to the iconAnchor
  });

const SearchControl = ({ onSearch }) => {
  const [input, setInput] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(input.toUpperCase().trim());
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Search postcode..."
      />
      <button type="submit">Search</button>
    </form>
  );
};

const ChangeView = ({ center, zoom }) => {
  const map = useMap();
  map.setView(center, zoom);
  return null;
};

const MerchantMap = () => {
  const [postcodes, setPostcodes] = useState([]);
  const [center, setCenter] = useState([51.505, -0.09]);
  const [zoom, setZoom] = useState(6);

  // Fetch postcodes from Firestore
  useEffect(() => {
    const fetchPostcodes = async () => {
      const querySnapshot = await getDocs(collection(db, "postcodes"));
      const postData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data() // Assuming your documents contain 'lat' and 'lng' fields
      }));
      setPostcodes(postData);
    };

    fetchPostcodes();
  }, []);

  const handleSearch = (searchQuery) => {
    const foundPostcode = postcodes.find((p) => p.postcode?.startsWith(searchQuery));
    if (foundPostcode) {
      setCenter([foundPostcode.lat, foundPostcode.lng]);
      setZoom(13); // Adjust zoom level as needed
    } else {
      alert('Postcode not found');
    }
  };

  return (
    <div>
      <SearchControl onSearch={handleSearch} />
      <MapContainer center={center} zoom={zoom} style={{ height: '100vh', width: '100%' }}>
        <ChangeView center={center} zoom={zoom} />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {postcodes.map(postcode => (
          <Marker 
          key={postcode.id} 
          position={[postcode.lat, postcode.lng]}
          icon={customIcon}
          >
            <Popup>
              {postcode.id} <br /> 
              {postcode.name} <br /> 
              {postcode.postcode} <br />
              {postcode.phone}
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default MerchantMap;
