// src/firebase/firebaseService.js

import { storage } from './firebaseConfig'; // Adjust the import path based on your file structure
import { ref, uploadBytes, listAll, getDownloadURL, deleteObject } from "firebase/storage";

export const uploadFileToNotice = async (file) => {
  const storageRef = ref(storage, `Notice/${file.name}`);
  await uploadBytes(storageRef, file);
};

export const listFilesInNotice = async () => {
  const listRef = ref(storage, 'Notice');
  const filesSnapshot = await listAll(listRef);
  const files = await Promise.all(filesSnapshot.items.map(async (itemRef) => {
    const url = await getDownloadURL(itemRef);
    return { name: itemRef.name, url: url };
  }));
  return files;
};

export const deleteFileFromNotice = async (fileName) => {
  const fileRef = ref(storage, `Notice/${fileName}`);
  await deleteObject(fileRef);
};
