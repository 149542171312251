import React, { useState, useEffect } from 'react';
import { db } from '../firebase/firebaseConfig';
import { collection, doc, setDoc, getDocs, addDoc } from 'firebase/firestore';

const AddProductForm = () => {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [productCode, setProductCode] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [description, setDescription] = useState('');
  const [imageName, setImageName] = useState('');
  const [dimensions, setDimensions] = useState([]);
  const [allParts, setAllParts] = useState([]);
  const [selectedParts, setSelectedParts] = useState([]);
  const [productDetails, setProductDetails] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      const categorySnapshot = await getDocs(collection(db, 'product_info'));
      const categoriesList = [];
      categorySnapshot.forEach(doc => {
        if (doc.data().category && !categoriesList.includes(doc.data().category)) {
          categoriesList.push(doc.data().category);
        }
      });
      setCategories(categoriesList);
    };

    const fetchParts = async () => {
      const partsSnapshot = await getDocs(collection(db, 'parts_details'));
      const partsList = [];
      partsSnapshot.forEach(doc => {
        partsList.push({ id: doc.id, ...doc.data() });
      });
      setAllParts(partsList);
    };

    fetchCategories();
    fetchParts();
  }, []);

  const handleAddDimension = () => {
    setDimensions([...dimensions, { name: '', height: '', size: '' }]);
  };

  const handleDimensionChange = (index, field, value) => {
    const newDimensions = dimensions.map((dimension, i) => 
      i === index ? { ...dimension, [field]: value } : dimension
    );
    setDimensions(newDimensions);
  };

  const handleAddProductDetail = () => {
    setProductDetails([...productDetails, { detail: '' }]);
  };

  const handleProductDetailChange = (index, value) => {
    const newProductDetails = productDetails.map((detail, i) => 
      i === index ? { ...detail, detail: value } : detail
    );
    setProductDetails(newProductDetails);
  };

  const handlePartSelection = (partId) => {
    setSelectedParts((prevSelectedParts) =>
      prevSelectedParts.includes(partId)
        ? prevSelectedParts.filter((id) => id !== partId)
        : [...prevSelectedParts, partId]
    );
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();
    const newProduct = {
      category: newCategory || selectedCategory,
      description,
      imageName,
      parts: selectedParts,
      productDetails: productDetails.map(detail => detail.detail),
    };

    const productDocRef = doc(db, 'product_info', productCode); // Use productCode as the document ID
    await setDoc(productDocRef, newProduct);

    const dimensionsPromises = dimensions.map((dimension) =>
      addDoc(collection(db, 'product_info', productDocRef.id, 'dimensions'), dimension)
    );

    await Promise.all(dimensionsPromises);

    // Reset form
    setNewCategory('');
    setProductCode('');
    setSelectedCategory('');
    setDescription('');
    setImageName('');
    setDimensions([]);
    setSelectedParts([]);
    setProductDetails([]);
  };

  return (
    <div>
      <h2>Add New Product</h2>
      <form onSubmit={handleAddProduct}>
        <div>
          <label>New Category</label>
          <input 
            type="text" 
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            placeholder="Enter new category"
          />
        </div>
        <div>
          <label>Or Select Category</label>
          <select 
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            disabled={!!newCategory}
          >
            <option value="">Select a category</option>
            {categories.map((category, index) => (
              <option key={index} value={category}>{category}</option>
            ))}
          </select>
        </div>
        <div>
          <label>Product Code</label>
          <input 
            type="text" 
            value={productCode}
            onChange={(e) => setProductCode(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Description</label>
          <textarea 
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Image Name</label>
          <input 
            type="text" 
            value={imageName}
            onChange={(e) => setImageName(e.target.value)}
            required
          />
        </div>
        <div>
          <h3>Dimensions</h3>
          {dimensions.map((dimension, index) => (
            <div key={index}>
              <label>Name</label>
              <input 
                type="text" 
                value={dimension.name}
                onChange={(e) => handleDimensionChange(index, 'name', e.target.value)}
              />
              <label>Height (mm)</label>
              <input 
                type="text" 
                value={dimension.height}
                onChange={(e) => handleDimensionChange(index, 'height', e.target.value)}
              />
              <label>Size</label>
              <input 
                type="text" 
                value={dimension.size}
                onChange={(e) => handleDimensionChange(index, 'size', e.target.value)}
              />
            </div>
          ))}
          <button type="button" onClick={handleAddDimension}>Add Dimension</button>
        </div>
        <div>
          <h3>Parts</h3>
          {allParts.map((part) => (
            <div key={part.id}>
              <label>
                <input 
                  type="checkbox"
                  value={part.id}
                  checked={selectedParts.includes(part.id)}
                  onChange={() => handlePartSelection(part.id)}
                />
                {part.part_name}
              </label>
            </div>
          ))}
        </div>
        <div>
          <h3>Product Details</h3>
          {productDetails.map((detail, index) => (
            <div key={index}>
              <label>Detail</label>
              <input 
                type="text" 
                value={detail.detail}
                onChange={(e) => handleProductDetailChange(index, e.target.value)}
              />
            </div>
          ))}
          <button type="button" onClick={handleAddProductDetail}>Add Product Detail</button>
        </div>
        <button type="submit">Add Product</button>
      </form>
    </div>
  );
};

export default AddProductForm;
