import React, { useState } from 'react';

function EmailForm() {
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');

    const sendEmail = () => {
        const emailDetails = {
            to: email,
            subject: subject,
            text: message
        };

        fetch('http://localhost:3005/send-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailDetails)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            if (!data.success) {
                throw new Error(data.message);
            }
            setStatus(`Email sent successfully: ${data.message}`);
        })
        .catch((error) => {
            setStatus(`Failed to send email: ${error.message}`);
        });
    };

    return (
        <div style={styles.container}>
            <input
                style={styles.input}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Recipient's email"
            />
            <input
                style={styles.input}
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder="Subject"
            />
            <textarea
                style={styles.textarea}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Message"
            />
            <button onClick={sendEmail} style={styles.button}>Send Email</button>
            <div style={styles.result}>{status}</div>
        </div>
    );
}

// Add your CSS styles here
const styles = {
    container: {
      textAlign: 'center',
      padding: '20px',
      margin: '20px auto',
      maxWidth: '500px',
      border: '1px solid #ccc',
      borderRadius: '8px',
      backgroundColor: '#f9f9f9',
    },
    input: {
      width: '100%',
      padding: '10px',
      marginBottom: '10px',
      border: '1px solid #ccc',
      borderRadius: '4px',
    },
    textarea: {
      width: '100%',
      height: '150px',
      padding: '10px',
      marginBottom: '10px',
      border: '1px solid #ccc',
      borderRadius: '4px',
    },
    button: {
      width: '100%',
      backgroundColor: '#4CAF50',
      color: 'white',
      padding: '10px',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    result: {
      marginTop: '20px',
    },
};

export default EmailForm;
