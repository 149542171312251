import React, { useState } from 'react';
import { getFirestore, collection, getDocs, writeBatch } from 'firebase/firestore';

const UpdateStock = () => {
  const [updating, setUpdating] = useState(false);
  const [updateResult, setUpdateResult] = useState(null);
  const firestore = getFirestore();

  const handleUpdateStock = async () => {
    setUpdating(true);
    setUpdateResult(null);
    
    const batch = writeBatch(firestore);
    const cylindersCollection = collection(firestore, 'cylinders_warehouse');

    try {
      const snapshot = await getDocs(cylindersCollection);

      snapshot.forEach((doc) => {
        batch.update(doc.ref, { Stock: true });
      });

      await batch.commit();
      setUpdateResult('All documents updated successfully!');
    } catch (error) {
      console.error('Error updating documents:', error);
      setUpdateResult(`Error: ${error.message}`);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div>
      <button onClick={handleUpdateStock} disabled={updating}>
        {updating ? 'Updating...' : 'Update All Stock to True'}
      </button>
      {updateResult && <p>{updateResult}</p>}
    </div>
  );
};

export default UpdateStock;
