import React, { useState } from 'react';
import FavouriteButton from './FavouriteButton';

function HotWaterCylinderSizeCalculator() {
  const [people, setPeople] = useState(1);
  const [bathrooms, setBathrooms] = useState(1);
  const [bedrooms, setBedrooms] = useState(1);
  const [hasMixedTaps, setHasMixedTaps] = useState(true); // Checkbox state
  const [calculatedSize, setCalculatedSize] = useState('');

  const calculateSize = () => {
    let baseCapacity = hasMixedTaps ? 70 : 90; // Base capacity depends on tap type
    let additionalCapacity = hasMixedTaps ? 45 : 60; // Additional capacity per person

    // Calculate the total capacity required
    let totalCapacity = baseCapacity + (additionalCapacity * (people - 1));
    if (bathrooms > 1) {
      totalCapacity += (bathrooms - 1) * 30; // Add 30L for each additional bathroom
    }

    // Adjust for bedrooms
    if (bedrooms > people) {
        totalCapacity += (bedrooms - people) * (hasMixedTaps ? 45 : 60); // Correctly adds based on tap type
      }      

    setCalculatedSize(`${Math.round(totalCapacity)} litres`);
  };

  return (
<>
    <FavouriteButton pageUrl={window.location.pathname} />
    <div style={styles.container}>
      <h2>Hot Water Cylinder Size Calculator</h2>
      <div style={styles.inputGroup}>
        <label>
          People:
          <input
            type="number"
            value={people}
            onChange={(e) => setPeople(Number(e.target.value))}
            style={styles.input}
          />
        </label>
      </div>
      <div style={styles.inputGroup}>
        <label>
          Bathrooms:
          <input
            type="number"
            value={bathrooms}
            onChange={(e) => setBathrooms(Number(e.target.value))}
            style={styles.input}
          />
        </label>
      </div>
      <div style={styles.inputGroup}>
        <label>
          Bedrooms:
          <input
            type="number"
            value={bedrooms}
            onChange={(e) => setBedrooms(Number(e.target.value))}
            style={styles.input}
          />
        </label>
      </div>
      <div style={styles.inputGroup}>
        <label>
          <input
            type="checkbox"
            checked={hasMixedTaps}
            onChange={(e) => setHasMixedTaps(e.target.checked)}
          />
          Property has mixed taps
        </label>
      </div>
      <button onClick={calculateSize} style={styles.button}>Calculate</button>
      <h3 style={styles.result}>Recommended Cylinder Size: {calculatedSize}</h3>
    </div>
</>
  );
}

// Add your CSS styles here
const styles = {
    container: {
      margin: '0 auto',
      marginTop: '20px',
      maxWidth: '500px',
      padding: '20px',
      border: '1px solid #ccc',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      backgroundColor: '#f9f9f9',
    },
    header: {
      textAlign: 'center',
      color: '#333',
    },
    inputGroup: {
      marginBottom: '15px',
    },
    label: {
      display: 'block',
      marginBottom: '5px',
      color: '#666',
    },
    input: {
      width: '100%',
      padding: '8px',
      margin: '0 0 10px 0',
      border: '1px solid #ddd',
      borderRadius: '4px',
      boxSizing: 'border-box', // Makes sure the padding doesn't affect the overall width
    },
    button: {
      width: '100%',
      backgroundColor: '#5c6bc0',
      color: 'white',
      padding: '10px',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    result: {
      textAlign: 'center',
      color: '#5c6bc0',
    },
  };

export default HotWaterCylinderSizeCalculator;
