  import React, { useEffect, useState } from 'react';
  import { db } from '../firebase/firebaseConfig';
  import { collection, onSnapshot } from 'firebase/firestore';
  import { IconButton } from '@mui/material';
  import { CheckCircleOutline, HighlightOff } from '@mui/icons-material';

  const Machines = () => {
    const [machines, setMachines] = useState([]);

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'machines'), (snapshot) => {
            const machinesData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setMachines(machinesData);
        });

        return () => unsubscribe();  // Clean up on unmount
    }, []);

    return (
        <div style={{ padding: 20 }}>
            <h1>Warehouse Operational Status</h1>
            <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                {machines.map((machine) => (
                    <div key={machine.id} style={{ margin: 10, textAlign: 'center' }}>
                        <IconButton style={{ color: machine.status ? 'green' : 'red', fontSize: 30 }}>
                            {machine.status ? <CheckCircleOutline fontSize="inherit" /> : <HighlightOff fontSize="inherit" />}
                        </IconButton>
                        <div>{machine.name}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Machines;