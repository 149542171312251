import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import './FileUpload.css';

const FileUploadPage = () => {
  const [serialNumber, setSerialNumber] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [files, setFiles] = useState([]);
  const storage = getStorage();
  const firestore = getFirestore();

  const onDrop = useCallback(acceptedFiles => {
    // Accumulate the dropped files with the existing ones
    setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
  }, []);

  const handleUpload = async () => {
    if (!serialNumber || !customerName) {
      alert('Both Serial Number and Customer Name are required.');
      return;
    }

    // Initialize an array to hold file references
    const fileRefs = [];

    // Upload files and collect references
    const uploadTasks = files.map(file => {
      const storageRef = ref(storage, `uploads/${file.name}`);
      return uploadBytes(storageRef, file).then(snapshot => {
        console.log('Uploaded a blob or file!', snapshot);
        return snapshot.metadata.fullPath; // Collect the path for Firestore
      });
    });

    // Wait for all uploads to finish
    Promise.all(uploadTasks)
      .then(paths => {
        // All files uploaded successfully, paths contains all file references
        fileRefs.push(...paths);

        // Now add a single document to Firestore with references to all files
        const docRef = collection(firestore, "files");
        return addDoc(docRef, {
          files: fileRefs, // Save file paths or URLs as an array
          serialNumber,
          customerName,
          createdAt: new Date(),
          sketchCreated: false,
          orderChecked: false,
          bomCreated: false,
          customerEmailed: false,
          bomBox: "",
          customerEmail: "",
          customerApproved: false,
          programComplete: false,
          readyForProduction: false,
          commentsBox: ""
        });
      })
      .then(() => {
        console.log('Document with file references added to Firestore');
        // Clear the state after successful upload
        setFiles([]);
        setSerialNumber('');
        setCustomerName('');
      })
      .catch(error => {
        console.error("Error during file upload or document creation: ", error);
      });
  };

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  return (
    <div className="page-container">
      <div className="upload-container">
        <input 
          type="text" 
          placeholder="Serial Number" 
          value={serialNumber} 
          onChange={e => setSerialNumber(e.target.value)}
        />
        <input 
          type="text" 
          placeholder="Customer Name" 
          value={customerName} 
          onChange={e => setCustomerName(e.target.value)}
        />
        <div {...getRootProps()} style={{border: '2px dashed #007bff', padding: '20px', cursor: 'pointer'}}>
          <input {...getInputProps()} />
          {isDragActive ?
            <p>Drop the files here ...</p> :
            <p>Click here or drag files.</p>
          }
        </div>
        <ul>
          {files.map((file, index) => (
            <li key={index}>{file.name}</li>
          ))}
        </ul>
        <button onClick={handleUpload}>Upload Files</button>
      </div>
    </div>
  );
};

export default FileUploadPage;
