import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, getFirestore, orderBy, doc, getDoc } from 'firebase/firestore';
import Papa from 'papaparse';
import DatePicker from 'react-datepicker';
import './WarehouseView.css';
import 'react-datepicker/dist/react-datepicker.css';

const StainlessPunchView = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [items, setItems] = useState([]);
  const [itemCodes, setItemCodes] = useState({});
  const firestore = getFirestore();

  useEffect(() => {
    const fetchItemCodes = async () => {
      const cylInfoRef = collection(firestore, 'cyl_info');
      const snapshot = await getDocs(cylInfoRef);
      const codes = {};
      snapshot.forEach(doc => {
        codes[doc.id] = doc.data().productCode;
      });
      setItemCodes(codes);
    };

    fetchItemCodes();
  }, [firestore]);

  useEffect(() => {
    const fetchData = async () => {
      const start = new Date(startDate.setHours(0, 0, 0, 0));
      const end = new Date(endDate.setHours(23, 59, 59, 999));
      const itemsRef = collection(firestore, 'stainlesspunch');
      const q = query(
        itemsRef,
        where("TimeScannedIn", ">=", start),
        where("TimeScannedIn", "<=", end),
        orderBy("TimeScannedIn")
      );

      try {
        const querySnapshot = await getDocs(q);
        const itemsList = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          Item: itemCodes[doc.data().Item] || doc.data().Item  // Replace numeric code with human-readable code if available
        }));
        setItems(itemsList);
      } catch (error) {
        console.error("Error fetching items: ", error);
      }
    };

    fetchData();
  }, [startDate, endDate, firestore, itemCodes]); // Include itemCodes as a dependency

  useEffect(() => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const filtered = items.filter(item =>
      item.SerialNumber.toLowerCase().includes(lowercasedSearchTerm) ||
      item.Item.toLowerCase().includes(lowercasedSearchTerm)
    );
    setFilteredItems(filtered);
  }, [items, searchTerm]);

  const handleExport = () => {
    const formattedStartDate = startDate.toISOString().split('T')[0];
    const formattedEndDate = endDate.toISOString().split('T')[0];
    const csvData = [
      ["Serial Number", "Quantity Number", "Item", "Time Scanned In"],
      ...filteredItems.map(item => [
        item.SerialNumber,
        item.QuantityNumber,
        item.Item,
        new Date(item.TimeScannedIn.seconds * 1000).toLocaleString()
      ])
    ];
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `punch_entries_${formattedStartDate}_to_${formattedEndDate}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div>
      <h1>View Punch Entries - Currently showing {filteredItems.length} items</h1>
      <div>
        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
        <input
          type="text"
          placeholder="Search by Serial Number or Product Code"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
        < br/>
        <button onClick={handleExport}>Export to CSV</button>
      </div>
      <div>
        <table className="warehouse-table">
          <thead>
            <tr>
              <th>Serial Number</th>
              <th>Quantity Number</th>
              <th>Item</th>
              <th>Time Scanned In</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems.map((item, index) => (
              <tr key={index}>
                <td>{item.SerialNumber}</td>
                <td>{item.QuantityNumber}</td>
                <td>{item.Item}</td>
                <td>{new Date(item.TimeScannedIn.seconds * 1000).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StainlessPunchView;
