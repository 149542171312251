import React, { useState } from 'react';
import { db } from '../firebase/firebaseConfig';
import { utils, writeFile } from 'xlsx';
import { getDocs, collection } from 'firebase/firestore';

const MassDownload = () => {
  const [isLoading, setIsLoading] = useState(false);

  // Recursive function to fetch documents and their sub-collections with all fields
  const fetchDocumentWithSubCollections = async (collectionPath) => {
    const data = [];
    const querySnapshot = await getDocs(collection(db, collectionPath));

    for (let document of querySnapshot.docs) {
      // Fetch the main document fields
      const docData = document.data();
      docData.docId = document.id; // Add document ID to data

      // Initialize an array to hold sub-collection data
      docData.subCollections = [];

      // Sub-collections to fetch
      const subCollectionPaths = [
        'connection_sizes',
        'data',
        'dimensions',
        'parts',
      ];

      // Fetch each sub-collection and its fields
      for (let subPath of subCollectionPaths) {
        const subCollectionPath = `${collectionPath}/${document.id}/${subPath}`;
        const subCollectionSnapshot = await getDocs(collection(db, subCollectionPath));

        // Collect all documents in the sub-collection
        const subCollectionData = subCollectionSnapshot.docs.map((subDoc) => ({
          subDocId: subDoc.id,
          ...subDoc.data(), // Fetch all fields from each sub-collection document
        }));

        docData.subCollections.push({
          name: subPath,
          data: subCollectionData,
        });
      }

      // Add the main document and its sub-collections to the result array
      data.push(docData);
    }
    return data;
  };

  // Main function to fetch and export data
  const fetchDataAndExport = async () => {
    setIsLoading(true);
    const mainCollectionPath = 'dimensions'; // Your main collection path
    const data = await fetchDocumentWithSubCollections(mainCollectionPath);

    // Flatten data for easier export to Excel
    const flattenedData = data.map((doc) => ({
      docId: doc.docId,
      ...doc, // Include all fields of the main document
      subCollections: JSON.stringify(doc.subCollections, null, 2), // Convert sub-collections to string for Excel
    }));

    // Convert data to worksheet using utils
    const ws = utils.json_to_sheet(flattenedData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Dimensions');

    // Create XLSX file and download using writeFile
    writeFile(wb, 'dimensions.xlsx');
    setIsLoading(false);
  };

  return (
    <div>
      <button onClick={fetchDataAndExport} disabled={isLoading}>
        {isLoading ? 'Exporting...' : 'Export Dimensions to Excel'}
      </button>
    </div>
  );
};

export default MassDownload;
