import React, { useState, useEffect } from 'react';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { Link } from 'react-router-dom'; // Assuming you're using React Router for navigation

const ReturnsList = () => {
    const [reports, setReports] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const firestore = getFirestore();

    useEffect(() => {
        const fetchReports = async () => {
            const reportsCollection = collection(firestore, 'returns');
            const reportSnapshot = await getDocs(reportsCollection);
            const reportList = reportSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setReports(reportList);
        };

        fetchReports();
    }, []);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const filteredReports = reports.filter(report =>
        report.returnsNoteNumber.toLowerCase().includes(searchQuery) ||
        report.serialNumber.toLowerCase().includes(searchQuery) ||
        report.sretNumber.toLowerCase().includes(searchQuery)
    );

    return (
        <div>
            <h1>View Reports</h1>
            <input
                type="text"
                placeholder="Search by note, serial, or sret number..."
                value={searchQuery}
                onChange={handleSearchChange}
            />
            <ul>
                {filteredReports.length > 0 ? (
                    filteredReports.map(report => (
                        <li key={report.id}>
                            <Link to={`/reports/${report.id}`}>
                                Returns Note: {report.returnsNoteNumber}, Serial Number: {report.serialNumber}, Sret Number: {report.sretNumber}
                            </Link>
                        </li>
                    ))
                ) : (
                    <li>No reports found.</li>
                )}
            </ul>
        </div>
    );
};

export default ReturnsList;
