import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDmAoRsNPHiA1LveYfXGOnOrENcX9yK2T4",
  authDomain: "peters-help-desk.firebaseapp.com",
  projectId: "peters-help-desk",
  storageBucket: "peters-help-desk.appspot.com",
  messagingSenderId: "839940266279",
  appId: "1:839940266279:web:8b55e47116f6303f063102",
  measurementId: "G-ZCPFG50REK"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const storage = getStorage(app);

export { app, db, storage };