// SketchDashboard.jsx
import React from 'react';
import './Dashboard.css';
import uploadIcon from './assets/upload.png';
import emailIcon from './assets/mail.png';
import importIcon from './assets/import.png';
import deleteIcon from './assets/delete.png';
import { useNavigate } from 'react-router-dom';

const AdminDashboard = () => {
  const navigate = useNavigate();


  const handleMassUploadClick = () => {
    navigate('/MassUpload');
  };

  const handleMassDownloadClick = () => {
    navigate('/MassDownload');
  };

  const handleManDataClick = () => {
    navigate('/DeleteManufactureData');
  };

  const handleEmailTestClick = () => {
    navigate('/EmailTestComponent');
  }

  const handleWarehouseDeleteClick = () => {
    navigate('/WarehouseDelete');
  }

  const handleDuplicateClick = () => {
    navigate('/DuplicateFirestoreForm');
  }

  const handleDuplicateWarehouseClick = () => {
    navigate('/DuplicateWarehouse');
  }

  const handleStockUpdateClick = () => {
    navigate('/UpdateStock');
  }

  const handleProductClick = () => {
    navigate('/AddProductForm');
  }

  const handleProductUpload = () => {
    navigate('/ProductUpload');
  }

  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <h1>Admin Panel</h1>
        {/* You can add a logout or navigation buttons here if needed */}
      </div>
      <div className="widget-container">
        {/* Add widgets and content specific to the Warehouse Dashboard */}
        <div className="widget" onClick={handleMassUploadClick}>
                <img src={uploadIcon} alt="File Upload" />
                <p>Mass Upload</p>
                {/* Additional content for Helpdesk */}
              </div>
        <div className="widget" onClick={handleMassDownloadClick}>
                <img src={uploadIcon} alt="File Upload" />
                <p>Mass Download</p>
                {/* Additional content for Helpdesk */}
              </div>
        <div className="widget" onClick={handleEmailTestClick}>
                <img src={emailIcon} alt="File Upload" />
                <p>Email Test</p>
                {/* Additional content for Helpdesk */}
              </div>
        <div className="widget" onClick={handleManDataClick}>
                <img src={deleteIcon} alt="File Upload" />
                <p>Delete Manufacturing Data</p>
                {/* Additional content for Helpdesk */}
              </div>
        <div className="widget" onClick={handleDuplicateClick}>
                <img src={deleteIcon} alt="File Upload" />
                <p>Duplication</p>
                {/* Additional content for Helpdesk */}
              </div>
        <div className="widget" onClick={handleDuplicateWarehouseClick}>
                <img src={deleteIcon} alt="File Upload" />
                <p>Duplication Warehouse</p>
                {/* Additional content for Helpdesk */}
              </div>
        <div className="widget" onClick={handleWarehouseDeleteClick}>
                <img src={deleteIcon} alt="File Upload" />
                <p>Warehouse Delete</p>
                {/* Additional content for Helpdesk */}
              </div>
        <div className="widget" onClick={handleStockUpdateClick}>
                <img src={deleteIcon} alt="File Upload" />
                <p>Update to Stock</p>
                {/* Additional content for Helpdesk */}
              </div>
        <div className="widget" onClick={handleProductClick}>
                <img src={importIcon} alt="File Upload" />
                <p>Add a product</p>
                {/* Additional content for Helpdesk */}
              </div>
        <div className="widget" onClick={handleProductUpload}>
                <img src={importIcon} alt="File Upload" />
                <p>Product Upload</p>
                {/* Additional content for Helpdesk */}
              </div>
        
        {/* Add more widgets as needed */}
      </div>
    </div>
  );
};

export default AdminDashboard;
