import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import Accordion from './Accordion'; // A component for individual accordion
import './ProductAccordion.css';

const ProductPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate(); // Initialize the navigate function

  // Static list of products
  const products = [
    
{ id: "TSMD090_info", code: "TSMD090", description: "Tempest Stainless Unvented Direct 90L Cylinder", category: "Tempest Direct - (TSMD)" }, 

{ id: "TSMD125_info", code: "TSMD125", description: "Tempest Stainless Unvented Direct 125L Cylinder", category: "Tempest Direct - (TSMD)" }, 

{ id: "TSMD150_info", code: "TSMD150", description: "Tempest Stainless Unvented Direct 150L Cylinder", category: "Tempest Direct - (TSMD)" }, 

{ id: "TSMD170_info", code: "TSMD170", description: "Tempest Stainless Unvented Direct 170L Cylinder", category: "Tempest Direct - (TSMD)" },

{ id: "TSMD200_info", code: "TSMD200", description: "Tempest Stainless Unvented Direct 200L Cylinder", category: "Tempest Direct - (TSMD)" },

{ id: "TSMD250_info", code: "TSMD250", description: "Tempest Stainless Unvented Direct 250L Cylinder", category: "Tempest Direct - (TSMD)" },

{ id: "TSMD300_info", code: "TSMD300", description: "Tempest Stainless Unvented Direct 300L Cylinder", category: "Tempest Direct - (TSMD)" },

{ id: "TSMD090SL_info", code: "TSMD090SL", description: "Tempest Stainless Unvented Direct Slimline 90L Cylinder", category: "Tempest Direct - (TSMDSL)" },

{ id: "TSMD125SL_info", code: "TSMD125SL", description: "Tempest Stainless Unvented Direct Slimline 125L Cylinder", category: "Tempest Direct - (TSMDSL)" },

{ id: "TSMD150SL_info", code: "TSMD150SL", description: "Tempest Stainless Unvented Direct Slimline 150L Cylinder", category: "Tempest Direct - (TSMDSL)" },

{ id: "TSMD170SL_info", code: "TSMD170SL", description: "Tempest Stainless Unvented Direct Slimline 170L Cylinder", category: "Tempest Direct - (TSMDSL)" },

{ id: "TSMD200SL_info", code: "TSMD200SL", description: "Tempest Stainless Unvented Direct Slimline 200L Cylinder", category: "Tempest Direct - (TSMDSL)" },

{ id: "TSMD250SL_info", code: "TSMD250SL", description: "Tempest Stainless Unvented Direct Slimline 250L Cylinder", category: "Tempest Direct - (TSMDSL)" },

{ id: "TSMD300SL_info", code: "TSMD300SL", description: "Tempest Stainless Unvented Direct Slimline 300L Cylinder", category: "Tempest Direct - (TSMDSL)" },

{ id: "TSMI090_info", code: "TSMI090", description: "Tempest Stainless Unvented Indirect 90L Cylinder", category: "Tempest Indirect - (TSMI)" },

{ id: "TSMI125_info", code: "TSMI125", description: "Tempest Stainless Unvented Indirect 125L Cylinder", category: "Tempest Indirect - (TSMI)" },

{ id: "TSMI150_info", code: "TSMI150", description: "Tempest Stainless Unvented Indirect 150L Cylinder", category: "Tempest Indirect - (TSMI)" },

{ id: "TSMI170_info", code: "TSMI170", description: "Tempest Stainless Unvented Indirect 170L Cylinder", category: "Tempest Indirect - (TSMI)" },

{ id: "TSMI200_info", code: "TSMI200", description: "Tempest Stainless Unvented Indirect 200L Cylinder", category: "Tempest Indirect - (TSMI)" },

{ id: "TSMI250_info", code: "TSMI250", description: "Tempest Stainless Unvented Indirect 250L Cylinder", category: "Tempest Indirect - (TSMI)" },

{ id: "TSMI300_info", code: "TSMI300", description: "Tempest Stainless Unvented Indirect 300L Cylinder", category: "Tempest Indirect - (TSMI)" },

{ id: "TSMI400_info", code: "TSMI400", description: "Tempest Stainless Unvented Indirect 400L Cylinder", category: "Tempest Indirect - (TSMI)" },

{ id: "TSMI500_info", code: "TSMI500", description: "Tempest Stainless Unvented Indirect 500L Cylinder", category: "Tempest Indirect - (TSMI)" },

{ id: "TSMI125SL_info", code: "TSMI125SL", description: "Tempest Stainless Unvented Indirect Slimline 125L Cylinder", category: "Tempest Indirect - (TSMISL)" },

{ id: "TSMI150SL_info", code: "TSMI150SL", description: "Tempest Stainless Unvented Indirect Slimline 150L Cylinder", category: "Tempest Indirect - (TSMISL)" },

{ id: "TSMI170SL_info", code: "TSMI170SL", description: "Tempest Stainless Unvented Indirect Slimline 170L Cylinder", category: "Tempest Indirect - (TSMISL)" },

{ id: "TSMI200SL_info", code: "TSMI200SL", description: "Tempest Stainless Unvented Indirect Slimline 200L Cylinder Option 1 (1800x470)", category: "Tempest Indirect - (TSMISL)" },

{ id: "TSMI200SL_info", code: "TSMI200SL", description: "Tempest Stainless Unvented Indirect Slimline 200L Cylinder Option 2 (1500x510)", category: "Tempest Indirect - (TSMISL)" },

{ id: "TSMI250SL_info", code: "TSMI250SL", description: "Tempest Stainless Unvented Indirect Slimline 250L Cylinder", category: "Tempest Indirect - (TSMISL)" },

{ id: "TSMI300SL_info", code: "TSMI300SL", description: "Tempest Stainless Unvented Indirect Slimline 300L Cylinder", category: "Tempest Indirect - (TSMISL)" },

{ id: "TSMI090-SPLAN_info", code: "TSMI090-SPLAN", description: "Tempest Stainless Unvented Indirect Pre-Plumbed 90L Cylinder", category: "Tempest Indirect - (TSMI-SPLAN)" },

{ id: "TSMI125-SPLAN_info", code: "TSMI125-SPLAN", description: "Tempest Stainless Unvented Indirect Pre-Plumbed 125L Cylinder", category: "Tempest Indirect - (TSMI-SPLAN)" },

{ id: "TSMI150-SPLAN_info", code: "TSMI150-SPLAN", description: "Tempest Stainless Unvented Indirect Pre-Plumbed 150L Cylinder", category: "Tempest Indirect - (TSMI-SPLAN)" },

{ id: "TSMI170-SPLAN_info", code: "TSMI170-SPLAN", description: "Tempest Stainless Unvented Indirect Pre-Plumbed 170L Cylinder", category: "Tempest Indirect - (TSMI-SPLAN)" },

{ id: "TSMI200-SPLAN_info", code: "TSMI200-SPLAN", description: "Tempest Stainless Unvented Indirect Pre-Plumbed 200L Cylinder", category: "Tempest Indirect - (TSMI-SPLAN)" },

{ id: "TSMI250-SPLAN_info", code: "TSMI250-SPLAN", description: "Tempest Stainless Unvented Indirect Pre-Plumbed 250L Cylinder", category: "Tempest Indirect - (TSMI-SPLAN)" },

{ id: "TSMI300-SPLAN_info", code: "TSMI300-SPLAN", description: "Tempest Stainless Unvented Indirect Pre-Plumbed 300L Cylinder", category: "Tempest Indirect - (TSMI-SPLAN)" },

{ id: "TSMI400-SPLAN_info", code: "TSMI400-SPLAN", description: "Tempest Stainless Unvented Indirect Pre-Plumbed 400L Cylinder", category: "Tempest Indirect - (TSMI-SPLAN)" },

{ id: "TSMI500-SPLAN_info", code: "TSMI500-SPLAN", description: "Tempest Stainless Unvented Indirect Pre-Plumbed 500L Cylinder", category: "Tempest Indirect - (TSMI-SPLAN)" },

{ id: "TSMI170-HP_info", code: "TSMI170-HP", description: "Tempest Stainless Unvented Indirect Heat Pump 170L Cylinder", category: "Tempest Indirect - (TSMI-HP)" },

{ id: "TSMI200-HP_info", code: "TSMI200-HP", description: "Tempest Stainless Unvented Indirect Heat Pump 200L Cylinder", category: "Tempest Indirect - (TSMI-HP)" },

{ id: "TSMI250-HP_info", code: "TSMI250-HP", description: "Tempest Stainless Unvented Indirect Heat Pump 250L Cylinder", category: "Tempest Indirect - (TSMI-HP)" },

{ id: "TSMI300-HP_info", code: "TSMI300-HP", description: "Tempest Stainless Unvented Indirect Heat Pump 300L Cylinder", category: "Tempest Indirect - (TSMI-HP)" },

{ id: "TSMI400-HP_info", code: "TSMI400-HP", description: "Tempest Stainless Unvented Indirect Heat Pump 400L Cylinder", category: "Tempest Indirect - (TSMI-HP)" },

{ id: "TSMI500-HP_info", code: "TSMI500-HP", description: "Tempest Stainless Unvented Indirect Heat Pump 500L Cylinder", category: "Tempest Indirect - (TSMI-HP)" },

{ id: "TSMI090H_info", code: "TSMI090H", description: "Tempest Stainless Unvented Indirect Horizontal 90L Cylinder", category: "Tempest Indirect - (TSMIH)" },

{ id: "TSMI125H_info", code: "TSMI125H", description: "Tempest Stainless Unvented Indirect Horizontal 125L Cylinder", category: "Tempest Indirect - (TSMIH)" },

{ id: "TSMI150H_info", code: "TSMI150H", description: "Tempest Stainless Unvented Indirect Horizontal 150L Cylinder", category: "Tempest Indirect - (TSMIH)" },

{ id: "TSMI170H_info", code: "TSMI170H", description: "Tempest Stainless Unvented Indirect Horizontal 170L Cylinder", category: "Tempest Indirect - (TSMIH)" },

{ id: "TSMI200H_info", code: "TSMI200H", description: "Tempest Stainless Unvented Indirect Horizontal 200L Cylinder", category: "Tempest Indirect - (TSMIH)" },

{ id: "TSMI250H_info", code: "TSMI250H", description: "Tempest Stainless Unvented Indirect Horizontal 250L Cylinder", category: "Tempest Indirect - (TSMIH)" },

{ id: "TSMI300H_info", code: "TSMI300H", description: "Tempest Stainless Unvented Indirect Horizontal 300L Cylinder", category: "Tempest Indirect - (TSMIH)" },

{ id: "TSMI400H_info", code: "TSMI400H", description: "Tempest Stainless Unvented Indirect Horizontal 400L Cylinder", category: "Tempest Indirect - (TSMIH)" },

{ id: "TSMI500H_info", code: "TSMI500H", description: "Tempest Stainless Unvented Indirect Horizontal 500L Cylinder", category: "Tempest Indirect - (TSMIH)" },

{ id: "TSMI125H-SL_info", code: "TSMI125H-SL", description: "Tempest Stainless Unvented Indirect Horizontal Slimline 125L Cylinder", category: "Tempest Indirect - (TSMIH-SL)" },

{ id: "TSMI150H-SL_info", code: "TSMI150H-SL", description: "Tempest Stainless Unvented Indirect Horizontal Slimline 150L Cylinder", category: "Tempest Indirect - (TSMIH-SL)" },

{ id: "TSMI170H-SL_info", code: "TSMI170H-SL", description: "Tempest Stainless Unvented Indirect Horizontal Slimline 170L Cylinder", category: "Tempest Indirect - (TSMIH-SL)" },

{ id: "TSMI200H-SL_info", code: "TSMI200H-SL", description: "Tempest Stainless Unvented Indirect Horizontal Slimline 200L Cylinder", category: "Tempest Indirect - (TSMIH-SL)" },

{ id: "TSMI250H-SL_info", code: "TSMI250H-SL", description: "Tempest Stainless Unvented Indirect Horizontal Slimline 250L Cylinder", category: "Tempest Indirect - (TSMIH-SL)" },

{ id: "TSMI300H-SL_info", code: "TSMI300H-SL", description: "Tempest Stainless Unvented Indirect Horizontal Slimline 300L Cylinder", category: "Tempest Indirect - (TSMIH-SL)" },

{ id: "TSMI090-HG_info", code: "TSMI090-HG", description: "Tempest Stainless Unvented Indirect High Gain 90L Cylinder", category: "Tempest Indirect - (TSMI-HG)" },

{ id: "TSMI125-HG_info", code: "TSMI125-HG", description: "Tempest Stainless Unvented Indirect High Gain 125L Cylinder", category: "Tempest Indirect - (TSMI-HG)" },

{ id: "TSMI150-HG_info", code: "TSMI150-HG", description: "Tempest Stainless Unvented Indirect High Gain 150L Cylinder", category: "Tempest Indirect - (TSMI-HG)" },

{ id: "TSMI170-HG_info", code: "TSMI170-HG", description: "Tempest Stainless Unvented Indirect High Gain 170L Cylinder", category: "Tempest Indirect - (TSMI-HG)" },

{ id: "TSMI200-HG_info", code: "TSMI200-HG", description: "Tempest Stainless Unvented Indirect High Gain 200L Cylinder", category: "Tempest Indirect - (TSMI-HG)" },

{ id: "TSMI250-HG_info", code: "TSMI250-HG", description: "Tempest Stainless Unvented Indirect High Gain 250L Cylinder", category: "Tempest Indirect - (TSMI-HG)" },

{ id: "TSMI300-HG_info", code: "TSMI300-HG", description: "Tempest Stainless Unvented Indirect High Gain 300L Cylinder", category: "Tempest Indirect - (TSMI-HG)" },

{ id: "TSMI400-HG_info", code: "TSMI400-HG", description: "Tempest Stainless Unvented Indirect High Gain 400L Cylinder", category: "Tempest Indirect - (TSMI-HG)" },

{ id: "TSMI500-HG_info", code: "TSMI500-HG", description: "Tempest Stainless Unvented Indirect High Gain 500L Cylinder", category: "Tempest Indirect - (TSMI-HG)" },

{ id: "TSMI170-S_info", code: "TSMI170-S", description: "Tempest Stainless Unvented Indirect Solar 170L Cylinder", category: "Tempest Indirect - (TSMI-S)" },

{ id: "TSMI200-S_info", code: "TSMI200-S", description: "Tempest Stainless Unvented Indirect Solar 200L Cylinder", category: "Tempest Indirect - (TSMI-S)" },

{ id: "TSMI250-S_info", code: "TSMI250-S", description: "Tempest Stainless Unvented Indirect Solar 250L Cylinder", category: "Tempest Indirect - (TSMI-S)" },

{ id: "TSMI300-S_info", code: "TSMI300-S", description: "Tempest Stainless Unvented Indirect Solar 300L Cylinder", category: "Tempest Indirect - (TSMI-S)" },

{ id: "TSMI400-S_info", code: "TSMI400-S", description: "Tempest Stainless Unvented Indirect Solar 400L Cylinder", category: "Tempest Indirect - (TSMI-S)" },

{ id: "TSMI500-S_info", code: "TSMI500-S", description: "Tempest Stainless Unvented Indirect Solar 500L Cylinder", category: "Tempest Indirect - (TSMI-S)" },

    // Add more products as needed
  ];

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter products based on search term or return all products
  const filteredProducts = products.filter(product => {
    const code = product.code || '';
    const description = product.description || '';
    const category = product.category || '';
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
  
    return code.toLowerCase().includes(lowerCaseSearchTerm) ||
           description.toLowerCase().includes(lowerCaseSearchTerm) ||
           category.toLowerCase().includes(lowerCaseSearchTerm);
  });

  // Group products by categories
  const categories = [...new Set(filteredProducts.map(product => product.category))];
  const productsByCategory = categories.map(category => ({
    category,
    items: filteredProducts.filter(product => product.category === category)
  }));

  const handleProductClick = (productCode) => {
    navigate(`/ActualProduct/${productCode}`); // Correctly append the productCode to the URL
  };

  return (
    <div>
      <h1>Product Catalogue</h1>
      <input type="text" placeholder="Search products..." onChange={handleSearchChange} />

      {productsByCategory.map(({ category, items }) => (
  <Accordion key={category} title={category}>
    {items.map(item => (
      <button key={item.id} className="product-button" onClick={() => handleProductClick(item.code)}>
        {item.code} - {item.description}
      </button>
    ))}
  </Accordion>
))}
    </div>
  );
};

export default ProductPage;
